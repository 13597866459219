import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, ReplaySubject, switchMap, tap } from 'rxjs';
import { Column, EditGridComponent, SvgIconRendererComponent } from '../../../common/components';
import { ErrorItem } from './Error';
import { initialColumns } from './initialColumns';

import { ErrorService } from '../../services/errors.service';

@UntilDestroy()
@Component({
    selector: 'app-errors',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        EditGridComponent,
        MatButtonModule,
        MatIcon,
        MatCheckboxModule,
        MatDivider,
        MatProgressSpinner,
        SvgIconRendererComponent,
    ],
    templateUrl: './errors.component.html',
    styleUrl: './errors.component.scss',
})
export class ErrorsComponent implements OnInit {
    protected areErrorsLoading = false;
    protected errors: ErrorItem[] = [];

    protected columns = new BehaviorSubject<Column<ErrorItem>[]>(initialColumns);
    protected refreshErrors = new ReplaySubject<void>(1);

    @ViewChild('customerNameCell') customerNameCellTemplate!: TemplateRef<unknown>;
    @ViewChild('dateSentHeader') dateSentHeaderTemplate!: TemplateRef<unknown>;
    @ViewChild('dateSentCell') dateSentCellTemplate!: TemplateRef<unknown>;

    constructor(
        private errorService: ErrorService,
        private cdr: ChangeDetectorRef
    ) {
        this.refreshErrors.next();
    }

    ngOnInit(): void {
        combineLatest([this.refreshErrors])
            .pipe(
                tap(() => (this.areErrorsLoading = true)),
                switchMap(() => this.errorService.handleGetGlobalErrorHistory()),
                tap((response) => {
                    this.errors = response.map((row) => ({
                        ...row,
                        CustomerName: row.Name,
                        ContactName: row.Contact,
                        EmailAddress: row.Email,
                        DocumentID: row.DocumentId,
                        DateSent: row.DateSent,
                        Errors: row.Error,
                        Resends: row.Attempts,
                    }));
                }),
                tap({
                    next: () => {
                        this.areErrorsLoading = false;
                        this.cdr.detectChanges();
                    },
                    error: () => {
                        this.areErrorsLoading = false;
                        this.cdr.detectChanges();
                    },
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    onErrorsCustomerClick(error: ErrorItem) {
        this.errorService.nextSelectedCustomerId(error.CusId);
        this.cdr.detectChanges();
    }
}
