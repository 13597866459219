<div class="edit-grid-container">
    <table
        mat-table
        #edit="matTable"
        [dataSource]="gridData"
        matSort
        (matSortChange)="sortChanged.emit($event)"
    >
        <ng-container
            *ngFor="let column of _columns; let columnIndex = index; trackBy: trackByFn"
            [matColumnDef]="column.field || ''"
            [sticky]="column.sticky === 'start' || column.sticky === true"
            [stickyEnd]="column.sticky === 'end'"
        >
            <th
                mat-header-cell
                *matHeaderCellDef
                [style]="column.headerStyle"
            >
                <div
                    *ngIf="!column.headerTemplate && !headerTemplates?.[column.field]"
                    [disabled]="!column.sortable"
                    [mat-sort-header]="column.field"
                    [hidden]="column.editOnly"
                    [class]="{ 'header-icon': column.headerIcon }"
                >
                    <span class="edit-grid-column-header">{{ column.headerName }}</span>
                    <span
                        *ngIf="column.headerIcon"
                        [title]="column.headerIconTitle"
                    >
                        <mat-icon>{{ column.headerIcon }}</mat-icon>
                    </span>
                </div>

                <ng-container
                    *ngIf="(column.headerTemplate || headerTemplates?.[column.field]) && !column.editOnly"
                    [ngTemplateOutlet]="column.headerTemplate || headerTemplates[column.field] || null"
                    [ngTemplateOutletContext]="{ $implicit: column }"
                />
            </th>

            <td
                mat-cell
                *matCellDef="let row; let rowIndex = index"
            >
                <ng-container *ngIf="editMode || row.editMode; else viewCell">
                    <div *ngIf="column.func">
                        <a
                            [style]="column.editStyle"
                            (click)="column.func(rowIndex)"
                            (keydown)="column.func(rowIndex)"
                            tabindex="0"
                            >{{ column.headerName }}</a
                        >
                    </div>

                    <div *ngIf="column.optionMenu">
                        <button
                            mat-icon-button
                            [matMenuTriggerFor]="menu"
                            title="Options"
                            [style]="column.editStyle"
                        >
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu
                            #menu="matMenu"
                            xPosition="after"
                        >
                            <div *ngFor="let opt of column.optionMenu">
                                <button
                                    mat-menu-item
                                    (click)="opt.func(row, rowIndex)"
                                    [hidden]="(opt.hideEdit && !row.isNew) || (row.isNew && opt.hideNew)"
                                >
                                    {{ opt.label }}
                                </button>
                            </div>
                        </mat-menu>
                    </div>

                    <mat-form-field
                        *ngIf="
                            column.inputType === 'text' &&
                            (column.createOnly ? row.canEdit : true) &&
                            !row.hiddenColumns?.includes(column.field)
                        "
                        [style]="column.editStyle"
                    >
                        <input
                            matInput
                            psDebounceKeyUp
                            name="textRow"
                            [(ngModel)]="row[column.field]"
                            (change)="itemChange(row, column.field, $event)"
                            [required]="column.required ?? false"
                            [maxlength]="column.maxLength ?? ''"
                        />
                    </mat-form-field>

                    <mat-form-field
                        *ngIf="column.inputType === 'currency' && (column.createOnly ? row.canEdit : true)"
                        [style]="column.editStyle"
                    >
                        <input
                            matInput
                            psDebounceKeyUp
                            name="currencyRow"
                            [(ngModel)]="row[column.field]"
                            (change)="itemChange(row, column.field, $event)"
                            [required]="column.required ?? false"
                            [maxlength]="column.maxLength ?? ''"
                            numberformat
                        />
                    </mat-form-field>

                    <mat-form-field
                        *ngIf="column.inputType === 'number' && (column.createOnly ? row.canEdit : true)"
                        [style]="column.editStyle"
                    >
                        <input
                            matInput
                            psDebounceKeyUp
                            name="numberRow"
                            [(ngModel)]="row[column.field]"
                            (change)="itemChange(row, column.field, $event)"
                            [required]="column.required ?? false"
                            [maxlength]="column.maxLength ?? ''"
                            numberformat
                        />
                    </mat-form-field>

                    <mat-form-field
                        *ngIf="column.inputType === 'typeahead' && (column.createOnly ? row.canEdit : true)"
                        [style]="column.editStyle"
                    >
                        <input
                            type="text"
                            matInput
                            psDebounceKeyUp
                            name="searchInput"
                            [(ngModel)]="row[column.field]"
                            (change)="itemChange(row, column.field, $event)"
                            (keydown.Tab)="itemChange(row, column.field, $event)"
                            [matAutocomplete]="searchAuto"
                            required
                        />
                        <mat-autocomplete
                            #searchAuto="matAutocomplete"
                            (optionSelected)="itemChange(row, column.field, $event)"
                        >
                            <mat-option
                                *ngFor="let option of column.selectOptions"
                                [value]="option.Id"
                            >
                                {{ option.Name }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                    <div
                        *ngIf="column.inputType === 'checkbox'"
                        [style]="column.editStyle"
                    >
                        <mat-checkbox
                            name="checkboxRow"
                            color="primary"
                            [(ngModel)]="row[column.field]"
                            (ngModelChange)="itemChange(row, column.field, $event)"
                            [disabled]="!(column.createOnly ? row.canEdit : true)"
                        />
                    </div>

                    <mat-form-field
                        *ngIf="column.inputType === 'select' && (column.createOnly ? row.canEdit : true)"
                        [style]="column.editStyle"
                    >
                        <mat-select
                            name="selectRow"
                            [(ngModel)]="row[column.field]"
                            (selectionChange)="itemChange(row, column.field, $event)"
                        >
                            <mat-option
                                *ngFor="let opt of column.selectOptions"
                                [value]="opt.Id"
                            >
                                {{ opt.Name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field
                        *ngIf="
                            column.inputType === 'datetime' &&
                            (column.createOnly ? row.canEdit : true) &&
                            !row.hiddenColumns?.includes(column.field)
                        "
                        [style]="column.editStyle"
                    >
                        <input
                            matInput
                            name="datePickerRow"
                            [matDatepicker]="picker"
                            [(ngModel)]="row[column.field]"
                            (dateInput)="itemChange(row, column.field, $event)"
                            [required]="column.required ?? false"
                        />
                        <mat-datepicker-toggle
                            matIconSuffix
                            [for]="picker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>

                    <ng-container
                        *ngIf="column.inputType === 'template'"
                        [ngTemplateOutlet]="column.cellTemplate || cellTemplates[column.field] || null"
                        [ngTemplateOutletContext]="{ $implicit: row, column: column }"
                    />

                    <div
                        *ngIf="
                            column.inputType === 'none' ||
                            (column.createOnly
                                ? !row.canEdit && column.inputType !== 'checkbox' && !column.optionMenu
                                : false)
                        "
                    >
                        {{ renderField(row, column) }}
                    </div>
                </ng-container>

                <ng-template #viewCell>
                    <div
                        [style]="column.viewStyle"
                        [hidden]="column.editOnly"
                        [title]="renderField(row, column)"
                    >
                        @switch (column.inputType) {
                            @case ('select') {
                                <div [style]="column.editStyle">
                                    <mat-select
                                        [disabled]="true"
                                        name="selectRow"
                                        [value]="row[column.field]"
                                    >
                                        <mat-option
                                            *ngFor="let opt of column.selectOptions"
                                            [value]="opt.Id"
                                        >
                                            {{ opt.Name }}
                                        </mat-option>
                                    </mat-select>
                                </div>
                            }
                            @case ('checkbox') {
                                <div [style]="column.editStyle">
                                    <mat-checkbox
                                        name="checkboxRow"
                                        [(ngModel)]="row[column.field]"
                                        disabled
                                    >
                                    </mat-checkbox>
                                </div>
                            }
                            @case ('link') {
                                <div>
                                    <button
                                        mat-stroked-button
                                        (click)="column.func?.(rowIndex)"
                                    >
                                        {{ column.field }}
                                    </button>
                                </div>
                            }
                            @case ('template') {
                                <ng-container
                                    [ngTemplateOutlet]="column.cellTemplate || cellTemplates[column.field] || null"
                                    [ngTemplateOutletContext]="{
                                        $implicit: row,
                                        column: column,
                                        editMode: editMode || row.editMode,
                                    }"
                                />
                            }

                            @default {
                                <span>{{ renderField(row, column) }}</span>
                            }
                        }
                    </div>
                </ng-template>
            </td>

            <td
                mat-footer-cell
                *matFooterCellDef
                [style.width]="column.width"
            >
                <div
                    *ngIf="editMode && columnIndex === 0 && !gridOptions?.hideAdd"
                    [style]="column.viewStyle"
                >
                    <button
                        mat-mini-fab
                        aria-label="Add row"
                        (click)="addRowClick()"
                    >
                        <mat-icon aria-hidden="false">add</mat-icon>
                    </button>
                </div>

                <div
                    *ngIf="!editMode && summaryRow"
                    [style]="column.viewStyle"
                    [title]="renderField(summaryRow, column)"
                >
                    {{ renderField(summaryRow, column) }}
                </div>
            </td>
        </ng-container>

        <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr
            mat-row
            (click)="!editMode && !row.editMode ? rowClicked.emit(row) : null"
            *matRowDef="let row; columns: displayedColumns"
        ></tr>

        <tr
            *matNoDataRow
            class="mat-row"
        >
            <td
                class="mat-cell"
                [attr.colspan]="_columns.length"
            >
                <ng-template
                    *ngIf="noDataTemplate; else noData"
                    [ngTemplateOutlet]="noDataTemplate"
                />
                <ng-template #noData>
                    <div class="no-data">
                        <mat-icon aria-hidden="true">info</mat-icon>
                        <span>No data available</span>
                    </div>
                </ng-template>
            </td>
        </tr>

        <ng-container *ngIf="gridOptions?.showFooterRow">
            <tr
                mat-footer-row
                *matFooterRowDef="displayedColumns"
            ></tr>
        </ng-container>
    </table>
</div>
