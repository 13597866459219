export const environment = {
    production: false,
    ApiSettings: {
        serviceUrl: 'https://secure.procedesoftware.com/PUBLIC/excede-api/v2-3/api/',
        clientId: '92C165A7-F2A4-44D8-8817-3D06A200652E',
        branchId: '100',
        tenantId: '',
        subjectType: 'Employee',
        subject: '12345',
        password: 'pass123',
        alternateSubject: '12345',
    },
    ApplicationSettings: {
        idleTimeoutSeconds: 3600,
        alertTimeoutSeconds: 7,
    },
    version: 'v2.1.0-rc3',
};
