<div
    class="container"
    [class.opacity]="areMessagesLoading"
>
    <div class="container-header">
        <h2>Delivery Status</h2>

        <div>
            <button
                mat-button
                [disabled]="!messageHistoryItems.length"
                (click)="refreshMessageHistory.next()"
            >
                Refresh Emails
            </button>

            <mat-divider
                vertical
                [style]="{ height: '20px' }"
            ></mat-divider>

            <button
                mat-button
                [disabled]="!messageHistoryItems.length"
                (click)="resendEmails()"
            >
                Resend Emails
            </button>
        </div>
    </div>

    <mat-divider />

    <div
        class="grid-container"
        matSort
        (matSortChange)="sortData($event)"
    >
        <app-edit-grid
            [columns$]="columns"
            [gridData]="messageHistoryItems"
            [cellTemplates]="{
                DocumentIdList: documentIdList,
                actionsEmail: emailButton,
                DateSent: deliveryDate,
                Resend: resendCheckbox,
                actionsEnd: deleteButton,
            }"
            [headerTemplates]="{
                Name: textFilter,
                EmailWork: textFilter,
                DateSent: textHeader,
                actionsEmail: textHeader,
                DocumentIdList: textFilter,
                InvoiceType: selectFilter,
                Status: selectFilter,
                Error: selectFilter,
            }"
            [noDataTemplate]="!customerId ? noData : undefined"
            (sortChanged)="sortData($event)"
        >
            <ng-template #noData>
                <h2
                    [style]="{
                        paddingBlock: '20px',
                        textAlign: 'center',
                    }"
                >
                    Please select a customer to view contacts.
                </h2>
            </ng-template>

            <ng-template
                #textFilter
                let-column
            >
                <div class="sort-header">
                    <span
                        [disabled]="!column.sortable"
                        [mat-sort-header]="column.field"
                        >{{ column.headerName }}</span
                    >
                    <mat-form-field
                        [formGroup]="searchParamsControl"
                        [style]="column.viewStyle"
                    >
                        <input
                            matInput
                            [formControlName]="column.field"
                        />
                    </mat-form-field>
                </div>
            </ng-template>

            <ng-template
                #selectFilter
                let-column
            >
                <div class="sort-header">
                    <span
                        [disabled]="!column.sortable"
                        [mat-sort-header]="column.field"
                        >{{ column.headerName }}</span
                    >
                    <mat-form-field
                        [formGroup]="searchParamsControl"
                        [style]="column.viewStyle"
                    >
                        <select
                            matNativeControl
                            [formControlName]="column.field"
                        >
                            <option
                                *ngFor="let opt of getOptionsMap(column.field)"
                                [value]="opt.value"
                            >
                                {{ opt.label }}
                            </option>
                        </select>
                    </mat-form-field>
                </div>
            </ng-template>

            <ng-template
                #textHeader
                let-column
            >
                <div class="sort-header">
                    <span
                        [disabled]="!column.sortable"
                        [mat-sort-header]="column.field"
                        >{{ column.headerName }}</span
                    >
                    <mat-form-field
                        [style]="column.viewStyle"
                        [style.visibility]="'hidden'"
                    >
                        <input matInput />
                    </mat-form-field>
                </div>
            </ng-template>

            <!-- Cell templates -->
            <ng-template
                #documentIdList
                let-row
            >
                <button
                    *ngIf="row.DocumentIdList.length > 1; else singleAttachment"
                    mat-button
                    (click)="attachmentsClicked(row)"
                >
                    {{ row.DocumentIdList.length + ' Attachments' }}
                </button>
                <ng-template #singleAttachment>
                    <span [title]="row.DocumentIdList[0]">{{ row.DocumentIdList[0] }}</span>
                </ng-template>
            </ng-template>

            <ng-template
                #deliveryDate
                let-row
            >
                <span title="This is when the email was originally sent">
                    {{ row.DateSent | date: 'MMM dd, yyyy h:mm a' }}
                </span>
            </ng-template>

            <ng-template
                #emailButton
                let-row
            >
                <button
                    mat-button
                    (click)="openMessage(row)"
                >
                    View
                </button>
            </ng-template>

            <ng-template
                #resendCheckbox
                let-row
                let-column="column"
            >
                <mat-checkbox
                    name="resendCheckbox"
                    [(ngModel)]="row[column.field]"
                    [disabled]="row.Resend && row.Status === 'Resend Pending'"
                />
            </ng-template>

            <ng-template
                #deleteButton
                let-row
            >
                <button
                    mat-icon-button
                    color="error"
                    (click)="deleteMessage(row)"
                >
                    <mat-icon>delete</mat-icon>
                </button>
            </ng-template>
        </app-edit-grid>
    </div>
</div>

<div
    *ngIf="areMessagesLoading"
    class="loader absolute-center"
>
    <mat-spinner diameter="64" />
</div>
