<div
    class="container"
    [class.opacity]="areContactsLoading"
>
    <div class="container-header">
        <h2>Contacts</h2>

        <div>
            <button
                mat-button
                [disabled]="contacts.length === 0"
                (click)="toggleEditMode()"
            >
                {{ (editMode | async) ? 'Cancel' : 'Edit' }}
            </button>

            <button
                *ngIf="editMode | async"
                mat-flat-button
                color="primary"
                [disabled]="contacts.length === 0"
                (click)="saveAllContacts()"
            >
                Save
            </button>
        </div>
    </div>

    <mat-divider />

    <div class="grid-container">
        <app-edit-grid
            (rowClicked)="rowClicked($event)"
            (itemChanged)="itemChanged($event)"
            [editMode$]="editMode"
            [columns$]="columns"
            [gridData]="contacts"
            [cellTemplates]="{
                actionsEnd: rowActions,
            }"
            [noDataTemplate]="!_selectedCustomer ? noData : undefined"
        />
        <ng-template #noData>
            <h2
                [style]="{
                    paddingBlock: '20px',
                    textAlign: 'center',
                }"
            >
                Please select a customer to view contacts.
            </h2>
        </ng-template>

        <ng-template
            #rowActions
            let-row
        >
            <div class="row-actions">
                <button
                    *ngIf="row.editMode"
                    mat-icon-button
                    color="primary"
                    (click)="saveContact(row)"
                >
                    <mat-icon>save</mat-icon>
                </button>

                <button
                    *ngIf="row.editMode"
                    mat-icon-button
                    (click)="$event.stopPropagation(); cancelEditRow(row)"
                >
                    <mat-icon>cancel</mat-icon>
                </button>

                <button
                    mat-icon-button
                    color="error"
                    (click)="deleteContact(row)"
                >
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </ng-template>
    </div>

    <div class="container-bottom">
        <button
            mat-button
            color="primary"
            [disabled]="contacts.length === 0"
            (click)="openAddContactDialog()"
        >
            <mat-icon
                [style]="{
                    fontSize: '24px',
                    width: '24px',
                    height: '24px',
                }"
                >add_circle</mat-icon
            >
            Add New
        </button>

        <mat-checkbox
            name="statementRequired"
            [(ngModel)]="statementIsRequired"
            [disabled]="contacts.length === 0"
            (change)="updateStatementRequired()"
            >Statement is Required
        </mat-checkbox>
    </div>
</div>

<div
    *ngIf="areContactsLoading"
    class="loader absolute-center"
>
    <mat-spinner diameter="64" />
</div>

<ng-template
    #selectAll
    let-column
>
    <div class="select-all-input">
        <span>{{ column.headerName }}</span>
        <mat-checkbox
            *ngIf="editMode | async"
            (change)="selectAllColumn(column, $event.checked)"
            >Select All</mat-checkbox
        >
    </div>
</ng-template>
