import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Observable, catchError, map, of, shareReplay } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SvgIconCacheService {
    private cache: Record<string, Observable<SafeHtml>> = {};

    constructor(
        private http: HttpClient,
        private sanitizer: DomSanitizer
    ) {}

    getIcon(iconName: string): Observable<SafeHtml> {
        if (!this.cache[iconName]) {
            const iconPath = `assets/icons/${iconName}.svg`;

            this.cache[iconName] = this.http.get(iconPath, { responseType: 'text' }).pipe(
                map((svg) => this.sanitizer.bypassSecurityTrustHtml(svg)),
                catchError((err) => {
                    console.error(`Could not load SVG icon: ${iconName}`, err);
                    return of('');
                }),
                shareReplay(1)
            );
        }

        return this.cache[iconName];
    }
}
