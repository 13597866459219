import { Routes } from '@angular/router';
import { AboutComponent } from './about/components';
import { LoginComponent } from './auth/components';
import { AuthGuard } from './auth/guards/auth.guard';
import { LayoutComponent } from './common/components';
import { ContactsViewComponent } from './contacts/components';

export const routes: Routes = [
    {
        path: '',
        redirectTo: '/contacts',
        pathMatch: 'full',
    },
    {
        path: 'login',
        title: 'Please Log In',
        component: LoginComponent,
    },
    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'contacts',
                title: 'Contacts',
                component: ContactsViewComponent,
            },
            {
                path: 'about',
                title: 'About',
                component: AboutComponent,
            },
        ],
    },
    {
        path: '**',
        redirectTo: '/contacts',
    },
];
