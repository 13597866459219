import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
    BehaviorSubject,
    combineLatest,
    filter,
    forkJoin,
    map,
    Observable,
    of,
    ReplaySubject,
    switchMap,
    tap,
} from 'rxjs';
import { SessionService } from '../../../auth/services/session.service';
import {
    Column,
    ConfirmationDialogComponent,
    EditGridComponent,
    ItemChangedEvent,
    SvgIconRendererComponent,
} from '../../../common/components';
import { Customer } from '../../../common/interfaces';
import { AlertService, CustomerService } from '../../../common/services';
import { isNullOrUndefined } from '../../../common/utils';
import { ScheduleService } from '../../../schedules/services/schedule.service';
import { ContactsService } from '../../services/contacts.service';
import { ContactDeliverySettings } from './../../interfaces/Contact';
import { ContactItem } from './Contact';
import { checkboxColumns, initialColumns } from './initialColumns';

const hasProperty = (object: object, property: string): boolean => {
    return Object.prototype.hasOwnProperty.call(object, property);
};

interface ScheduleOption {
    Id: number;
    Name: string;
    IsDefault: boolean;
}

@UntilDestroy()
@Component({
    selector: 'app-contacts-setup-tab',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        EditGridComponent,
        MatButtonModule,
        MatIcon,
        MatCheckboxModule,
        MatDivider,
        MatProgressSpinner,
        SvgIconRendererComponent,
    ],
    templateUrl: './contacts-setup-tab.component.html',
    styleUrl: './contacts-setup-tab.component.scss',
})
export class ContactsSetupTabComponent implements OnInit, AfterViewInit {
    @Input({ required: true })
    selectedCustomer!: Observable<Customer | null>;
    _selectedCustomer: Customer | null = null;

    areContactsLoading = false;

    @ViewChild('selectAll') selectAllTemplate!: TemplateRef<unknown>;

    contacts: ContactItem[] = [];
    contactsMemo: ContactItem[] = [];
    faxDomain: string;
    scheduleOptions: ScheduleOption[] = [];
    statementIsRequired = false;

    columns = new BehaviorSubject<Column<ContactItem>[]>(initialColumns);
    editMode = new BehaviorSubject(false);
    refreshContacts = new ReplaySubject<void>(1);

    constructor(
        private customerService: CustomerService,
        private contactService: ContactsService,
        private scheduleService: ScheduleService,
        private sessionService: SessionService,
        private alertService: AlertService,
        private dialog: MatDialog,
        private cdr: ChangeDetectorRef
    ) {
        this.faxDomain = this.sessionService.faxDomain ? '@' + this.sessionService.faxDomain.replace('@', '') : '';
        this.refreshContacts.next();
    }

    ngOnInit() {
        combineLatest([this.selectedCustomer, this.refreshContacts])
            .pipe(
                map(([customer]) => customer),
                filter((customer) => !!customer),
                tap((customer) => {
                    this._selectedCustomer = customer;
                    this.statementIsRequired = customer.StatementReqd === 1;
                }),
                tap(() => (this.areContactsLoading = true)),
                switchMap((customer) => this.contactService.listCustomerContacts(customer.Id)),
                tap((response) => {
                    this.contacts = response
                        .filter((c) => !c.Inactive)
                        .map((row) => ({
                            ...row,
                            SchedId: !isNullOrUndefined(row.SchedId) ? row.SchedId : 0,
                            SendOptions: !isNullOrUndefined(row.SendOptions) ? row.SendOptions : 2,
                            DeliveryOptions: !isNullOrUndefined(row.DeliveryOptions) ? row.DeliveryOptions : 1,
                            AllSelected: this.getAllSelectedValue(row),
                            Parts: !!row.Parts,
                            Service: !!row.Service,
                            LeaseRental: !!row.LeaseRental,
                            Fuel: !!row.Fuel,
                            Cash: !!row.Cash,
                            ARStmt: !!row.ARStmt,
                            Fax: (row.PhoneFax || '') + this.faxDomain,
                            Title: !hasProperty(response[0], 'Title') ? row.Typ : row.Title,
                        }));

                    this.contactsMemo = this.contacts.map((contact) => ({ ...contact }));
                }),
                tap(this.turnOffLoadingObserver()),
                untilDestroyed(this)
            )
            .subscribe();

        this.scheduleService
            .getGlobalScheduleItems()
            .pipe(
                tap((schedules) => {
                    this.scheduleOptions = schedules.map((schedule) => ({
                        Id: schedule.Id,
                        Name: schedule.Name,
                        IsDefault: schedule.IsDefault,
                    }));

                    const columnsWithScheduleOptions = this.columns.value.map((column) => {
                        if (column.field === 'SchedId' && column.inputType === 'select') {
                            column.selectOptions = this.scheduleOptions;
                        }

                        return column;
                    });

                    this.columns.next(columnsWithScheduleOptions);
                    this.cdr.detectChanges();
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    ngAfterViewInit() {
        const columnsWithTemplates = this.columns.value.map((column) => {
            if (column.inputType === 'checkbox') {
                column.headerTemplate = this.selectAllTemplate;
                const styles = {
                    textAlign: 'center',
                };

                column.editStyle = styles;
                column.viewStyle = styles;
            }

            return column;
        });

        this.columns.next(columnsWithTemplates);
    }

    saveAllContacts() {
        // TODO: Save prevention should be considered when form is invalid
        if (this.contacts.some((c) => !c.Name)) {
            return;
        }

        of(true)
            .pipe(
                tap(() => (this.areContactsLoading = true)),
                switchMap(() =>
                    forkJoin(this.contacts.map((contact) => this.contactService.updateContactDeliverySettings(contact)))
                ),
                tap(this.turnOffLoadingObserver()),
                tap(() => {
                    this.editMode.next(!this.editMode.value);
                    this.refreshContacts.next();
                    this.cdr.detectChanges();
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    selectAllColumn(column: Column<ContactItem>, value: boolean) {
        if (column.field === 'AllSelected') {
            this.contacts.forEach((row) => {
                this.handleSelectAllRow(row, column.field, value);
            });

            return;
        }

        this.contacts.forEach((row) => {
            // @ts-expect-error ts(2322)
            row[column.field] = value;
        });
    }

    itemChanged($event: ItemChangedEvent<ContactItem>) {
        const { colName } = $event;

        // @ts-expect-error ts(2345)
        if (checkboxColumns.includes(colName)) {
            this.handleSelectAllRow($event.row, colName, Boolean($event.event));
        }
    }

    saveContact(contact: ContactItem) {
        // TODO: Save prevention should be considered when form is invalid
        if (!contact.Name) {
            return;
        }

        of(true)
            .pipe(
                tap(() => (this.areContactsLoading = true)),
                switchMap(() => this.contactService.updateContactDeliverySettings(contact)),
                tap(this.turnOffLoadingObserver()),
                tap(() => {
                    this.refreshContacts.next();
                    this.cdr.detectChanges();
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    cancelEditRow(contact: ContactItem) {
        const contactIndex = this.contacts.findIndex((c) => c.ConId === contact.ConId);

        if (contactIndex !== -1) {
            this.contacts[contactIndex] = {
                ...this.contacts[contactIndex],
                ...this.contactsMemo[contactIndex],
                editMode: false,
            };
            this.contacts = [...this.contacts];
        }

        this.cdr.detectChanges();
    }

    deleteContact(contact: ContactItem) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {
                bodyText: `
                    Please confirm that you want to <strong>delete this contact.</strong>
                    <h4>${contact.Name} (${contact.EmailWork})</h4>
                `,
            },
            disableClose: true,
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((res) => !!res),
                tap(() => {
                    this.areContactsLoading = true;

                    this.cdr.detectChanges();
                }),
                switchMap(() => this.contactService.getDeliverySettings(contact.ItmId)),
                filter((res) => !!res),
                switchMap((response) => {
                    response.Inactive = true;

                    return this.contactService.updateDeliverySettings(response);
                }),
                tap(this.turnOffLoadingObserver()),
                tap(() => this.refreshContacts.next()),
                untilDestroyed(this)
            )
            .subscribe();
    }

    openAddContactDialog() {
        if (!this.sessionService.kioskMode$.value) {
            this.alertService.error('Add New Contact not supported outside of Excede.');
            return;
        }

        const defaultSchedule = this.scheduleOptions.find((item) => item.IsDefault);

        window.cefQuery({
            request: `2001||${this._selectedCustomer!.Id}`,
            onSuccess: (response) => {
                if (response === null) {
                    return;
                }

                const contactId = Number(response.split('\t')[1].trim());
                const newDocEmailItem = {
                    Id: 0,
                    CusId: this._selectedCustomer!.Id,
                    ConId: contactId,
                    SchedId: defaultSchedule?.Id ?? 0,
                    Parts: false,
                    Service: false,
                    LeaseRental: false,
                    Fuel: false,
                    Cash: false,
                    ARStmt: false,
                    Inactive: false,
                    DeliveryOptions: 1,
                    SendOptions: 2,
                } as const;

                of(1)
                    .pipe(
                        tap(() => (this.areContactsLoading = true)),
                        switchMap(() => this.contactService.createContactDeliverySettings(newDocEmailItem)),
                        tap(() => this.refreshContacts.next()),
                        tap(this.turnOffLoadingObserver()),
                        untilDestroyed(this)
                    )
                    .subscribe();
            },
            onFailure: (errorCode: string, errorMessage: string) => {
                this.alertService.error(`Adding new Contact failed: ${errorCode} - ${errorMessage}`);
            },
        });
    }

    updateStatementRequired() {
        if (!this._selectedCustomer) {
            return;
        }

        this._selectedCustomer.StatementReqd = this.statementIsRequired ? 1 : 0;

        of(true)
            .pipe(
                tap(() => (this.areContactsLoading = true)),
                switchMap(() => this.customerService.updateCustomer(this._selectedCustomer!)),
                tap(this.turnOffLoadingObserver()),
                untilDestroyed(this)
            )
            .subscribe();
    }

    rowClicked(contact: ContactItem) {
        const contactIndex = this.contacts.findIndex((c) => c.editMode);
        if (contactIndex !== -1) {
            this.cancelEditRow(this.contacts[contactIndex]);
        }

        const contactIndexToEdit = this.contacts.findIndex((c) => c.ConId === contact.ConId);
        if (contactIndexToEdit !== -1) {
            this.contacts[contactIndexToEdit].editMode = true;
        }

        this.cdr.detectChanges();
    }

    toggleEditMode() {
        this.contacts.forEach((c) => {
            this.cancelEditRow(c);
        });
        this.editMode.next(!this.editMode.value);
    }

    private handleSelectAllRow(row: ContactItem, columnName: Column<ContactItem>['field'], value: boolean) {
        if (columnName === 'AllSelected') {
            checkboxColumns.forEach((column) => {
                // @ts-expect-error ts(2322)
                row[column] = Boolean(value);
            });

            return;
        }

        row.AllSelected = this.getAllSelectedValue(row);
    }

    private getAllSelectedValue(row: ContactDeliverySettings): boolean {
        return [!!row.Parts, !!row.Service, !!row.LeaseRental, !!row.Fuel, !!row.Cash, !!row.ARStmt].every(Boolean);
    }

    private turnOffLoadingObserver() {
        return {
            next: () => this.turnOffLoading(),
            error: () => this.turnOffLoading(),
        };
    }

    private turnOffLoading() {
        this.areContactsLoading = false;
        this.cdr.detectChanges();
    }
}
