import { CommonModule } from '@angular/common';
import { Component, Type, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatDivider } from '@angular/material/divider';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput, MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabChangeEvent, MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, debounceTime, distinctUntilChanged, filter, map, shareReplay, switchMap, tap } from 'rxjs';
import { AboutComponent } from '../../../about/components';
import { AuthService } from '../../../auth/services/auth.service';
import { Customer } from '../../../common/interfaces';
import { AlertService, CustomerService } from '../../../common/services';
import { ExhaustivenessCheck } from '../../../common/utils';
import { DeliveryStatusComponent } from '../../../delivery-status/components';
import { ErrorsComponent } from '../../../errors/components';
import { DocumentDialogComponent, DocumentDialogType } from '../../../help/components';
import { SchedulesComponent } from '../../../schedules/components';
import { ArchiveService, ArchiveTestType } from '../../services/archive.service';
import { ContactsSetupTabComponent } from '../contacts-setup-tab/contacts-setup-tab.component';
import { ErrorService } from '../../../errors/services/errors.service';

type TabName = 'about' | 'errors' | 'schedules';

interface Tab {
    title: string;
    inputs?: Record<string, unknown>;
    component: Type<unknown>;
}

const AngularMaterialImports = [
    MatTabsModule,
    MatInput,
    MatLabel,
    MatIcon,
    MatDivider,
    MatIconButton,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
];

@UntilDestroy()
@Component({
    selector: 'app-contacts',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ContactsSetupTabComponent,
        ...AngularMaterialImports,
        DeliveryStatusComponent,
    ],
    templateUrl: './contacts-view.component.html',
    styleUrl: './contacts-view.component.scss',
})
export class ContactsViewComponent {
    extraTab: Tab | null = null;

    @ViewChild(MatTabGroup)
    private tabgroup!: MatTabGroup;

    readonly displayCustomer = (value: Customer) => (value ? `${value.Name} (${value.Id})` : '');
    readonly filterValues = <T>(searchPhrase: string | T): searchPhrase is string =>
        !!searchPhrase && typeof searchPhrase === 'string';

    customerSearchControl = new FormControl<string | Customer>('');

    customers$ = this.customerSearchControl.valueChanges.pipe(
        filter(this.filterValues),
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => this.searching$.next(true)),
        switchMap((searchPhrase) => this.customerService.searchCustomer(searchPhrase ?? '')),
        tap({ next: () => this.searching$.next(false), error: () => this.searching$.next(false) })
    );
    selectedCustomer$ = this.customerSearchControl.valueChanges.pipe(
        map((value) => {
            if (typeof value === 'string') {
                return null;
            }

            return value;
        }),
        shareReplay(1)
    );
    searching$ = new BehaviorSubject(false);

    constructor(
        private router: Router,
        private customerService: CustomerService,
        private authService: AuthService,
        private dialog: MatDialog,
        private archiveService: ArchiveService,
        private alertService: AlertService,
        private errorService: ErrorService
    ) {
        this.errorService
            .selectedCustomerIdObservable()
            .pipe(
                tap(() => this.searching$.next(true)),
                switchMap((cusId) => this.customerService.searchCustomer(cusId)),
                tap((customer) => this.customerSearchControl.setValue(customer[0])),
                tap(() => {
                    this.tabgroup.selectedIndex = 0;
                }),
                tap({ next: () => this.searching$.next(false), error: () => this.searching$.next(false) }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    openTab(tabName: TabName) {
        switch (tabName) {
            case 'about':
                this.extraTab = {
                    title: 'About',
                    component: AboutComponent,
                };
                break;
            case 'errors':
                this.extraTab = {
                    title: 'Errors',
                    component: ErrorsComponent,
                };
                break;
            case 'schedules':
                this.extraTab = {
                    title: 'Schedules',
                    inputs: { selectedCustomer: this.selectedCustomer$ },
                    component: SchedulesComponent,
                };
                break;
            default:
                throw new ExhaustivenessCheck(tabName);
        }

        if (this.extraTab) {
            this.tabgroup.selectedIndex = 2;
        }
    }

    openGuide(type: DocumentDialogType) {
        this.dialog.open(DocumentDialogComponent, {
            data: {
                type,
            },
            disableClose: true,
            width: '85vw',
            height: '90vh',
            maxWidth: '100%',
        });
    }

    onTestApiArchivePermissions(type: ArchiveTestType) {
        return this.archiveService
            .onTestApiArchivePermissions(type)
            .pipe(
                tap((errorMessages) => {
                    if (errorMessages.length === 0) {
                        this.alertService.log('Excede API has access to archived PDFs');
                        return;
                    }
                    this.alertService.error('Excede API is unable to access archived PDFs');
                    console.error('PDF Archive Access Error(s):\r\n', errorMessages.join('\r\n '));
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    tabChanged($event: MatTabChangeEvent) {
        if ($event.index !== 2) {
            this.extraTab = null;
        }
    }

    onLogout() {
        this.authService
            .logout()
            .pipe(
                tap({
                    complete: () => {
                        void this.router.navigateByUrl('/login');
                    },
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }
}
