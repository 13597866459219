import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

const DEFAULT_LOADING_MESSAGE = 'Loading...';

@Injectable({
    providedIn: 'root',
})
export class LoaderService {
    isLoading$ = new BehaviorSubject<boolean>(false);
    isLoading = this.isLoading$.asObservable();

    loadingMessage = DEFAULT_LOADING_MESSAGE;

    show() {
        this.isLoading$.next(true);
    }

    hide() {
        this.isLoading$.next(false);
    }

    toggleLoading() {
        this.isLoading$.next(!this.isLoading$.value);
    }

    setLoadingMessage(message: string) {
        this.loadingMessage = message;
    }

    resetLoadingMessage() {
        this.loadingMessage = DEFAULT_LOADING_MESSAGE;
    }
}
