import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    constructor(
        private http: HttpClient,
        private configService: ConfigService
    ) {}

    get<ReturnType>(endpoint: string, options?: Parameters<HttpClient['get']>[1]) {
        return this.http.get<ReturnType>(this.configService.config.apiSettings.serviceUrl + endpoint, options);
    }

    post<ReturnType, BodyType = unknown>(
        endpoint: string,
        request: BodyType,
        options?: Parameters<HttpClient['post']>[2]
    ) {
        return this.http.post<ReturnType>(
            this.configService.config.apiSettings.serviceUrl + endpoint,
            request,
            options
        );
    }

    put<ReturnType, BodyType = unknown>(endpoint: string, request: BodyType) {
        return this.http.put<ReturnType>(this.configService.config.apiSettings.serviceUrl + endpoint, request);
    }

    delete<ReturnType, BodyType = unknown>(endpoint: string, object: BodyType) {
        return this.http.delete<ReturnType>(this.configService.config.apiSettings.serviceUrl + endpoint, {
            body: object,
        });
    }
}
