import { Column } from '../../../common/components/edit-grid/Column';
import { MessageHistoryItem } from '../../interfaces/MessageHistoryItem';

export const initialColumns: Column<MessageHistoryItem>[] = [
    {
        field: 'Name',
        headerName: 'Contact Name',
        inputType: 'text',
        sticky: true,
        sortable: true,
        viewStyle: {
            width: '120px',
        },
    },
    {
        field: 'EmailWork',
        headerName: 'Email Address',
        inputType: 'text',
        sortable: true,
    },
    {
        field: 'InvoiceType',
        headerName: 'Invoice Type',
        inputType: 'text',
        sortable: true,
        viewStyle: {
            width: '100px',
        },
    },
    {
        field: 'DocumentIdList',
        headerName: 'Document ID',
        inputType: 'template',
        viewStyle: {
            width: '120px',
        },
    },
    {
        field: 'DateSent',
        headerName: 'Delivery Date',
        inputType: 'template',
        sortable: true,
        viewStyle: {
            width: '150px',
        },
    },
    {
        field: 'actionsEmail',
        headerName: 'Email',
        inputType: 'template',
        viewStyle: {
            width: '64px',
        },
    },
    {
        field: 'Status',
        headerName: 'Status',
        inputType: 'text',
        viewStyle: {
            width: '120px',
        },
    },
    {
        field: 'Error',
        headerName: 'Error',
        inputType: 'text',
        viewStyle: {
            width: '100px',
        },
    },
    {
        field: 'Resend',
        headerName: 'Resend',
        inputType: 'template',
        sticky: 'end',
        viewStyle: {
            width: '50px',
            textAlign: 'center',
        },
    },
    {
        field: 'actionsEnd',
        headerName: '',
        inputType: 'template',
        sticky: 'end',
    },
];
