import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
    MAT_DIALOG_DATA,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogTitle,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { SafeHtmlPipe } from '../../pipes/safe-html.pipe';

interface ConfirmationDialogData {
    title: string;
    bodyText: string;
    confirmButtonLabel: string;
    cancelButtonLabel: string;
    showCancelButton: boolean;
}

@Component({
    selector: 'app-document-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrl: './confirmation-dialog.component.css',
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatDialogTitle,
        MatDialogContent,
        MatDialogActions,
        MatDialogClose,
        MatIconModule,
        SafeHtmlPipe,
    ],
})
export class ConfirmationDialogComponent {
    readonly data = inject<Partial<ConfirmationDialogData>>(MAT_DIALOG_DATA);
    readonly title = this.data.title ?? 'Confirmation';
    readonly bodyText = this.data.bodyText ?? 'Are you sure you want to proceed?';

    readonly confirmButtonLabel = this.data.confirmButtonLabel ?? 'Confirm';
    readonly cancelButtonLabel = this.data.cancelButtonLabel ?? 'Cancel';
    readonly showCancelButton = this.data.showCancelButton ?? true;
}
