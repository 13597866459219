import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
    MAT_DIALOG_DATA,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogTitle,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { SafeUrlPipe } from '../../../common/pipes';

export type DocumentDialogType = 'product_guide' | 'troubleshoot_guide';

@Component({
    selector: 'app-document-dialog',
    templateUrl: './document-dialog.component.html',
    styleUrl: './document-dialog.component.css',
    standalone: true,
    imports: [
        MatButtonModule,
        MatDialogTitle,
        MatDialogContent,
        MatDialogActions,
        MatDialogClose,
        MatIconModule,
        SafeUrlPipe,
    ],
})
export class DocumentDialogComponent {
    readonly data = inject<{ type: DocumentDialogType } | { type: 'custom'; customTitle: string; documentUrl: string }>(
        MAT_DIALOG_DATA
    );
    readonly type = this.data.type;
    readonly title =
        this.data.type === 'custom'
            ? this.data.customTitle
            : this.data.type === 'product_guide'
              ? 'Document Delivery v2.0 Product Guide'
              : 'Document Delivery v2.0 Troubleshooting Guide';
    readonly document =
        this.data.type === 'custom'
            ? this.data.documentUrl
            : this.type == 'product_guide'
              ? 'assets/documents/Document Delivery v2.0 Product Guide.pdf'
              : 'assets/documents/Document Delivery v2.0 Troubleshooting Guide.pdf';
}
