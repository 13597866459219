import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { ApiService } from '../../common/services';
import { ScheduleItem } from '../interfaces/ScheduleItem';

@Injectable({
    providedIn: 'root',
})
export class ScheduleService {
    constructor(private apiService: ApiService) {}

    getGlobalScheduleItems() {
        const globalScheduleRequest = {
            Where: '1=1 AND SrcAppId = 1',
            OrderBy: '',
            Limit: 99999,
            Skip: 0,
            Includes: null,
        };

        return this.apiService
            .post<{ Items: ScheduleItem[] }>('search/application_schedule', globalScheduleRequest)
            .pipe(map((response) => response.Items));
    }

    listSchedules() {
        const scheduleRequest = {
            Where: ' 1=1 AND SrcAppId = 1',
            Limit: 1000,
            Skip: 0,
            Includes: ['DOC_EMAIL_Sched'],
        };

        return this.apiService
            .post<{ Items: ScheduleItem[] }>('search/application_schedule', scheduleRequest)
            .pipe(map((response) => response.Items));
    }

    createSchedule(schedule: ScheduleItem) {
        return this.apiService.post<ScheduleItem>('application_schedule', schedule);
    }

    updateSchedule(schedule: ScheduleItem) {
        return this.apiService.put<ScheduleItem>(`application_schedule/${schedule.Id}`, schedule);
    }

    deleteSchedule(schedule: ScheduleItem) {
        return this.apiService.delete<void>(`application_schedule/${schedule.Id}`, schedule);
    }
}
