import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogTitle,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, Subject, switchMap, tap } from 'rxjs';
import { DocumentDialogComponent } from '../../../help/components';
import { Attachment } from '../../interfaces/Attachment';
import { MessageHistoryItem } from '../../interfaces/MessageHistoryItem';
import { DeliveryStatusService } from '../../services/delivery-status.service';

@UntilDestroy()
@Component({
    selector: 'app-document-dialog',
    templateUrl: './view-email-dialog.component.html',
    styleUrl: './view-email-dialog.component.css',
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatDialogTitle,
        MatDialogContent,
        MatDialogActions,
        MatDialogClose,
        MatIconModule,
    ],
})
export class ViewEmailDialogComponent {
    readonly data = inject<MessageHistoryItem>(MAT_DIALOG_DATA);

    openPds$ = new Subject<Attachment>();

    constructor(
        private deliveryStatusService: DeliveryStatusService,
        private dialog: MatDialog
    ) {
        this.openPds$
            .pipe(
                debounceTime(200),
                switchMap((attachment) => this.openPdf(attachment)),
                untilDestroyed(this)
            )
            .subscribe();
    }

    openPdf(attachment: Attachment) {
        return this.deliveryStatusService.getAttachment(attachment.Id).pipe(
            switchMap((response) => {
                const blob = new Blob([response], { type: 'application/pdf' });
                const fileUrl = URL.createObjectURL(blob);

                return this.dialog
                    .open(DocumentDialogComponent, {
                        data: {
                            type: 'custom',
                            customTitle: 'View PDF',
                            documentUrl: fileUrl,
                        },
                        hasBackdrop: false,
                        width: '85vw',
                        height: '90vh',
                        maxWidth: '100%',
                    })
                    .afterOpened()
                    .pipe(
                        tap(() => {
                            URL.revokeObjectURL(fileUrl);
                        })
                    );
            })
        );
    }
}
