<h3
    mat-dialog-title
    class="dialog-title"
>
    {{ title }}
</h3>
<button
    mat-icon-button
    mat-dialog-close
    aria-label="Close"
    class="close-button"
>
    <mat-icon color="primary">close</mat-icon>
</button>
<div class="divider"></div>

<mat-dialog-content>
    <object
        type="application/pdf"
        class="object-container"
        [data]="document | safeUrl"
    >
        Document content
    </object>
</mat-dialog-content>

<mat-dialog-actions>
    <button
        mat-stroked-button
        color="secondary"
        [mat-dialog-close]="false"
    >
        Cancel
    </button>
</mat-dialog-actions>
