import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { SvgIconCacheService } from '../../services';

@Component({
    selector: 'app-svg-icon-renderer',
    standalone: true,
    templateUrl: './svg-icon-renderer.component.html',
    styleUrls: ['./svg-icon-renderer.component.css'],
    imports: [CommonModule],
})
export class SvgIconRendererComponent implements OnChanges {
    @Input({ required: true }) iconName!: string;

    svgContent$!: Observable<SafeHtml>;

    constructor(private iconCache: SvgIconCacheService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['iconName'] && this.iconName) {
            this.svgContent$ = this.iconCache.getIcon(this.iconName);
        }
    }
}
