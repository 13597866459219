import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, map, tap } from 'rxjs';
import { constants } from '../../app-constants';
import { Branch, Employee } from '../../common/interfaces';
import { ApiService, BranchService } from '../../common/services';
import { AlertService } from '../../common/services/alert.service';
import { ConfigService } from '../../common/services/config.service';
import { BRANCH_COOKIE_KEY, USER_COOKIE_KEY, isNullOrUndefined } from '../../common/utils';
import { DefaultBranchSettings } from '../constants';
import { BranchSettings } from '../interfaces';

@Injectable({
    providedIn: 'root',
})
export class SessionService {
    readOnly = false;
    isUserLoggedIn = false;
    userManuallyLoggedOut = false;
    userTimedOut = false;
    loggedInUser: Employee | null = null;
    loggedInBranch: Branch | null = null;
    ssoErrorMessage: string | null = null;

    token = '';
    tokenExpires = new Date();

    kioskMode$ = new BehaviorSubject(false);
    excedeToken = '';
    redirectPath = '';

    userBranches: Branch[] = [];
    branchSettings = new BehaviorSubject<BranchSettings>({ ...DefaultBranchSettings });
    deliveryOptionSettings = {
        Fax: false,
        Print: false,
    };
    faxDomain: string | null = null;
    arBranch: string | null = null;

    constructor(
        private configService: ConfigService,
        private cookieService: CookieService,
        private alertService: AlertService,
        private branchService: BranchService,
        private apiService: ApiService
    ) {}

    loginUser(employee: Employee, branch: Branch) {
        this.loggedInUser = employee;
        this.loggedInBranch = branch;

        this.clearCookies();
        this.cookieService.set(USER_COOKIE_KEY, JSON.stringify(employee), undefined, '/');
        this.cookieService.set(BRANCH_COOKIE_KEY, JSON.stringify(branch), undefined, '/');

        return this.checkReadOnly(branch).pipe(map(() => (this.isUserLoggedIn = true)));
    }

    logout(): void {
        this.loggedInUser = null;
        this.loggedInBranch = null;
        this.isUserLoggedIn = false;
        this.userBranches = [];
        this.branchSettings.next({ ...DefaultBranchSettings });

        this.clearCookies();

        this.token = '';
        this.tokenExpires = new Date();

        this.alertService.log('Logout successful');
    }

    timeOut() {
        this.logout();
        this.userManuallyLoggedOut = false;
        this.userTimedOut = true;
    }

    setToken(newToken: string, expires: Date | string) {
        this.token = newToken;
        this.tokenExpires = new Date(expires);
    }

    isTokenExpired(): boolean {
        return isNullOrUndefined(this.token) || this.tokenExpires <= new Date();
    }

    loggedInBranchId() {
        let branchId: string = this.configService.config.apiSettings.branchId;
        if (!isNullOrUndefined(this.loggedInBranch)) {
            branchId = this.loggedInBranch.BrnId || this.loggedInBranch.Id;
        }

        return branchId;
    }

    loggedInEmployeeId() {
        let employeeId = this.configService.config.apiSettings.alternateSubject;
        if (!isNullOrUndefined(this.loggedInUser)) employeeId = this.loggedInUser.Id;

        return employeeId;
    }

    setSsoErrorMessage(message: string | null): void {
        this.ssoErrorMessage = message;
    }

    setKioskMode(value: boolean) {
        this.kioskMode$.next(value);
    }

    isEmployeeAdmin(empId: string) {
        return this.branchService.getProcedeSettings(constants.MasterBranch).pipe(
            map((settings) => {
                this.setDocEmailDeliveryOptions(settings);

                const adminUsers = settings[constants.BranchSettings.AdminUsers];
                if (!isNullOrUndefined(adminUsers)) {
                    const adminEmpIds = adminUsers.split(',');

                    return adminEmpIds.includes(empId);
                }

                return false;
            })
        );
    }

    private setDocEmailDeliveryOptions(bcsSettings: Record<string, string>) {
        const faxSetting = !!bcsSettings[constants.BranchSettings.Fax];
        const printSetting = !!bcsSettings[constants.BranchSettings.Printer];

        const faxDomain = bcsSettings[constants.BranchSettings.Fax] ?? null;
        const arBranch = bcsSettings[constants.BranchSettings.ARBranch] ?? null;

        this.setDeliveryOptionSettings(faxSetting, printSetting);
        this.faxDomain = faxDomain;
        this.arBranch = arBranch;
    }

    private setDeliveryOptionSettings(fax: boolean, print: boolean) {
        this.deliveryOptionSettings.Fax = fax;
        this.deliveryOptionSettings.Print = print;
    }

    private checkReadOnly(branch: Branch) {
        const query = {
            Limit: 9999,
            Skip: 0,
            Where: `WspId == ${branch.WspId} && Typ = 1174`,
        };

        return this.apiService
            .post<{ TotalItems: number; Items: { Permissions: number }[] }>('search/company_workspace_item', query)
            .pipe(
                tap((response) => {
                    this.readOnly = response.TotalItems === 0 || response.Items[0].Permissions !== 375;
                })
            );
    }

    private clearCookies() {
        this.cookieService.delete(USER_COOKIE_KEY, '/');
        this.cookieService.delete(BRANCH_COOKIE_KEY, '/');
    }
}
