<div
    *ngIf="loaderService.isLoading | async"
    @fadeAnimation
    class="loader"
>
    <mat-spinner />
    <div>{{ loaderService.loadingMessage }}</div>
</div>

<app-standalone-navigation>
    <section class="content">
        <router-outlet></router-outlet>
    </section>
</app-standalone-navigation>
