import { Column } from '../../../common/components/edit-grid/Column';
import { ContactItem } from './Contact';

export const initialColumns: Column<ContactItem>[] = [
    {
        field: 'Name',
        headerName: 'Name',
        inputType: 'text',
        required: true,
        sticky: true,
        viewStyle: {
            width: '100px',
        },
    },
    {
        field: 'Title',
        headerName: 'Title',
        inputType: 'text',
    },
    {
        field: 'EmailWork',
        headerName: 'Work Email',
        inputType: 'text',
    },
    {
        field: 'AllSelected',
        headerName: 'Select All',
        inputType: 'checkbox',
        viewStyle: {
            width: '80px',
        },
    },
    {
        field: 'Parts',
        headerName: 'Parts',
        inputType: 'checkbox',
    },
    {
        field: 'Service',
        headerName: 'Service',
        inputType: 'checkbox',
    },
    {
        field: 'LeaseRental',
        headerName: 'Lease',
        inputType: 'checkbox',
    },
    {
        field: 'Fuel',
        headerName: 'Fuel',
        inputType: 'checkbox',
    },
    {
        field: 'ARStmt',
        headerName: 'AR',
        inputType: 'checkbox',
    },
    {
        field: 'Cash',
        headerName: 'Cash',
        inputType: 'checkbox',
    },
    {
        field: 'DeliveryOptions',
        headerName: 'Delivery Options',
        inputType: 'select',
        selectOptions: [
            { Id: 1, Name: 'Email' },
            { Id: 2, Name: 'Fax' },
            { Id: 3, Name: 'Print' },
        ],
        viewStyle: {
            width: '120px',
        },
    },
    {
        field: 'SendOptions',
        headerName: 'Attachments',
        inputType: 'select',
        selectOptions: [
            { Id: 1, Name: 'One/email' },
            { Id: 2, Name: 'One type/email' },
            { Id: 3, Name: 'Multiple/email' },
        ],
        viewStyle: {
            width: '130px',
        },
    },
    {
        field: 'SchedId',
        headerName: 'Invoice Schedule',
        inputType: 'select',
        selectOptions: [],
        viewStyle: {
            width: '150px',
        },
    },
    {
        field: 'actionsEnd',
        headerName: '',
        inputType: 'template',
        cellTemplate: null,
        sticky: 'end',
        editOnly: true,
    },
];

export const checkboxColumns = initialColumns
    .filter((column) => column.inputType === 'checkbox')
    .map((c) => c.field) as (keyof ContactItem)[];
