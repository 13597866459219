import { Injectable } from '@angular/core';
import {
    AuthenticationResult,
    BrowserCacheLocation,
    LogLevel,
    Configuration as MSALConfiguration,
    PublicClientApplication,
} from '@azure/msal-browser';
import { EMPTY, Observable, switchMap } from 'rxjs';
import { ConfigService } from '../../common/services';
import { isNullOrUndefined } from '../../common/utils';
import { SessionService } from './session.service';

interface AzureConfig {
    AzureAdClientId: string;
    AzureAdTenantId: string;
}

@Injectable({
    providedIn: 'root',
})
export class AzureService {
    constructor(
        private sessionService: SessionService,
        private configService: ConfigService
    ) {}

    public msalUserName: string | undefined = undefined;
    private msalConfig: MSALConfiguration | null = null;

    doAzureSignIn(email: string, azureConfig: AzureConfig) {
        this.msalConfig = this.generateMsalConfig(azureConfig);

        const msalClient = new PublicClientApplication(this.msalConfig);

        const loginRequest = {
            scopes: ['User.Read'],
            forceRefresh: true,
            loginHint: email,
            prompt: 'login',
        };

        return new Observable<AuthenticationResult | null>((observer) => {
            void msalClient
                .initialize()
                .then(() => msalClient.loginPopup(loginRequest))
                .then((response: AuthenticationResult | null) => {
                    this.msalUserName = response?.account.username;

                    observer.next(response);
                    observer.complete();
                })
                .catch((error) => {
                    observer.error(error);
                    observer.complete();
                });
        });
    }

    doAzureSignOut(): Observable<void> {
        const azureAdAuth = 877;
        if (!this.msalConfig) {
            return EMPTY;
        }

        return this.configService.getApiVersion().pipe(
            switchMap((apiVersion) => {
                if (
                    versionCompare(apiVersion ?? '0.0', '2.3') < 0 ||
                    this.sessionService.loggedInUser?.COEMPSEC_Emp?.AuthType !== azureAdAuth ||
                    !this.msalConfig
                ) {
                    return EMPTY;
                }

                const msalClient = new PublicClientApplication(this.msalConfig);
                const currentAccount = msalClient.getAccount({
                    username: this.sessionService.loggedInUser.COEMPSEC_Emp.Principal,
                });

                if (isNullOrUndefined(currentAccount?.idTokenClaims)) {
                    return EMPTY;
                }

                const logoutRequest = {
                    account: msalClient.getAccount({ username: this.msalUserName! }),
                    logoutHint: currentAccount.idTokenClaims.login_hint,
                    postLogoutRedirectUri: location.href.replace(location.pathname, '/login'),
                    mainWindowRedirectUri: location.href.replace(location.pathname, '/login'),
                };

                return msalClient.initialize().then(() => msalClient.logoutPopup(logoutRequest));
            })
        );
    }

    private generateMsalConfig(azureConfig: AzureConfig) {
        const redirectUri = `${location.hostname === 'localhost' ? 'http://localhost:3001' : window.location.href}/auth.html`;

        const msalConfig: MSALConfiguration = {
            auth: {
                clientId: azureConfig.AzureAdClientId,
                authority: `https://login.microsoftonline.com/${azureConfig.AzureAdTenantId}`,
                redirectUri,
            },
            cache: {
                cacheLocation: BrowserCacheLocation.SessionStorage,
            },
            system: {
                loggerOptions: {
                    loggerCallback: (level, message, containsPii) => {
                        if (containsPii) {
                            return;
                        }

                        switch (level) {
                            case LogLevel.Error:
                                console.error(message);
                                return;
                            case LogLevel.Info:
                                console.info(message);
                                return;
                            case LogLevel.Verbose:
                                console.debug(message);
                                return;
                            case LogLevel.Warning:
                                console.warn(message);
                                return;
                        }
                    },
                },
            },
        };

        return msalConfig;
    }
}

const versionCompare = (
    v1: string,
    v2: string,
    options: { lexicographical: boolean; zeroExtend: boolean } | undefined = undefined
) => {
    const lexicographical = options?.lexicographical;
    const zeroExtend = options?.zeroExtend;
    let v1parts: (number | string)[] = v1.split('.').map(Number);
    let v2parts: (number | string)[] = v2.split('.').map(Number);

    function isValidPart(x: string | number) {
        return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x.toString());
    }

    if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
        return NaN;
    }

    if (zeroExtend) {
        while (v1parts.length < v2parts.length) v1parts.push('0');
        while (v2parts.length < v1parts.length) v2parts.push('0');
    }

    if (!lexicographical) {
        v1parts = v1parts.map(Number);
        v2parts = v2parts.map(Number);
    }

    for (let i = 0; i < v1parts.length; ++i) {
        if (v2parts.length == i) {
            return 1;
        }

        if (v1parts[i] == v2parts[i]) {
            continue;
        }

        if (v1parts[i] > v2parts[i]) {
            return 1;
        } else {
            return -1;
        }
    }

    if (v1parts.length != v2parts.length) {
        return -1;
    }

    return 0;
};
