<div
    class="container"
    [class.opacity]="areSchedulesLoading"
>
    <div class="container-header">
        <h2>Invoice Schedules</h2>
    </div>

    <mat-divider />

    <div class="grid-container">
        <app-edit-grid
            [columns$]="columns"
            [gridData]="schedules"
            [cellTemplates]="{
                actionsStart: editButton,
                DateLast: readOnlyDate,
                DateNext: readOnlyDate,
                actionsEnd: rowActions,
            }"
            [noDataTemplate]="!_selectedCustomer ? noData : undefined"
        />
        <ng-template #noData>
            <h2
                [style]="{
                    paddingBlock: '20px',
                    textAlign: 'center',
                }"
            >
                Please select a customer to view contacts.
            </h2>
        </ng-template>

        <ng-template
            #editButton
            let-row
        >
            <button
                *ngIf="!row.editMode"
                mat-icon-button
                color="primary"
                (click)="editRow(row)"
            >
                <mat-icon aria-label="Edit">edit</mat-icon>
            </button>
        </ng-template>

        <ng-template
            #readOnlyDate
            let-row
            let-column="column"
        >
            <span>{{ row[column.field] | date: 'MM/dd/yyyy hh:mm a' }}</span>
        </ng-template>

        <ng-template
            #rowActions
            let-row
        >
            <div class="row-actions">
                <button
                    *ngIf="row.editMode"
                    mat-icon-button
                    color="primary"
                    (click)="saveSchedule(row)"
                >
                    <mat-icon>save</mat-icon>
                </button>

                <button
                    *ngIf="row.editMode"
                    mat-icon-button
                    (click)="$event.stopPropagation(); cancelEditRow(row)"
                >
                    <mat-icon>cancel</mat-icon>
                </button>

                <button
                    mat-icon-button
                    color="error"
                    (click)="deleteSchedule(row)"
                >
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </ng-template>
    </div>

    <div class="container-bottom">
        <button
            mat-button
            color="primary"
            [disabled]="!_selectedCustomer || !schedules.length"
            (click)="addNewSchedule()"
        >
            <mat-icon
                [style]="{
                    fontSize: '24px',
                    width: '24px',
                    height: '24px',
                }"
                >add_circle</mat-icon
            >
            Add New
        </button>
    </div>
</div>

<div
    *ngIf="areSchedulesLoading"
    class="loader absolute-center"
>
    <mat-spinner diameter="64" />
</div>
