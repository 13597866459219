<h3
    mat-dialog-title
    class="dialog-title"
>
    View Email
</h3>
<button
    mat-icon-button
    mat-dialog-close
    aria-label="Close"
    class="close-button"
>
    <mat-icon color="primary">close</mat-icon>
</button>
<div class="divider"></div>

<mat-dialog-content>
    <p><strong>Subject:</strong> {{ data.MailSubject }}</p>
    <p><strong>Recipient:</strong> {{ data.Name }} ({{ data.EmailWork }})</p>
    <p><strong>Date:</strong> {{ data.DateSent | date: 'MMM dd, yyyy h:mm a' }}</p>

    <p><strong>Body:</strong> {{ data.MailBody }}</p>

    <div *ngIf="data.Attachments.length">
        <strong>Attachments:</strong>

        <div class="attachments-list">
            <button
                *ngFor="let attachment of data.Attachments"
                mat-button
                (click)="openPds$.next(attachment)"
            >
                {{ attachment.FileName }}
            </button>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button
        mat-stroked-button
        color="secondary"
        [mat-dialog-close]="false"
    >
        Cancel
    </button>
    <button
        mat-flat-button
        [mat-dialog-close]="true"
        cdkFocusInitial
    >
        Resend
    </button>
</mat-dialog-actions>
