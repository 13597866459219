<h3
    mat-dialog-title
    class="dialog-title"
>
    {{ title }}
</h3>
<button
    mat-icon-button
    mat-dialog-close
    aria-label="Close"
    class="close-button"
>
    <mat-icon color="primary">close</mat-icon>
</button>
<div class="divider"></div>

<mat-dialog-content [innerHTML]="bodyText | safeHtml"> </mat-dialog-content>

<mat-dialog-actions>
    <button
        *ngIf="showCancelButton"
        mat-stroked-button
        color="secondary"
        [mat-dialog-close]="false"
    >
        {{ cancelButtonLabel }}
    </button>
    <button
        mat-flat-button
        [mat-dialog-close]="true"
        cdkFocusInitial
    >
        {{ confirmButtonLabel }}
    </button>
</mat-dialog-actions>
