import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs';
import { constants } from '../../app-constants';
import { BranchSettingsDTO } from '../../auth/interfaces';
import { Branch } from '../interfaces';
import { isNonNullish, isNullOrUndefined, isNullOrUndefinedOrBlank } from '../utils';
import { ApiService } from './api.service';

const ProcedeSoftwarePartnerId = 1535;

@Injectable({
    providedIn: 'root',
})
export class BranchService {
    constructor(private apiService: ApiService) {}

    getBranchesForEmployeeId(employeeId: string) {
        const request = {
            Where: `EmpId == "${employeeId}"`,
            OrderBy: 'BrnId asc',
            Includes: ['Brn'],
        };

        return this.apiService
            .post<{ Items: Branch[] }>('search/employee_branch', request)
            .pipe(map((response) => response?.Items ?? []));
    }

    getProcedeSettings(branchId: string) {
        return this.apiService.get<Record<string, string>>(`branch_setting/${branchId}/${ProcedeSoftwarePartnerId}`);
    }

    getBranchConfiguration(branchId: string) {
        const baseWhere = '" && ParentItm.Typ == 1535';

        const request = {
            Limit: 99999,
            Where: `ParentItm.BrnId == "${branchId}${baseWhere}`,
        };

        return this.apiService.post<{ Items: BranchSettingsDTO[] }>('search/branch_setting', request).pipe(
            map((response) => {
                if (!response?.Items) {
                    return [];
                }

                return response.Items.map((item) => {
                    if (!isNullOrUndefinedOrBlank(item.Valu)) {
                        return item;
                    }

                    return null;
                }).filter(isNonNullish);
            }),
            switchMap((branchConfigurationList) => {
                return this.apiService
                    .post<{ Items: BranchSettingsDTO[] }>('search/branch_setting', {
                        ...request,
                        Where: `ParentItm.BrnId == "${constants.MasterBranch}${baseWhere}`,
                    })
                    .pipe(
                        map((masterConfigRes) => {
                            if (!isNullOrUndefined(masterConfigRes) && masterConfigRes.Items) {
                                return branchConfigurationList.concat(
                                    masterConfigRes.Items.filter(
                                        (item) => !branchConfigurationList.find((config) => config.Name === item.Name)
                                    )
                                );
                            }

                            return branchConfigurationList;
                        })
                    );
            })
        );
    }

    getEmployeeBranches(branchId: string) {
        const request = {
            Where: `EmpId == "${branchId}"`,
            OrderBy: 'BrnId asc',
            Includes: ['Brn'],
        };

        return this.apiService.post<{ Items: Branch[] }>('search/employee_branch', request).pipe(
            map((response) => {
                if (!response) {
                    return [];
                }

                const branches = response.Items;

                branches.forEach((branch) => {
                    if (branch.BrnId === branchId) {
                        branch.checked = true;
                        branch.defaultBranch = true;
                    }
                });

                return branches;
            })
        );
    }

    getCompanyBranches(branchId: string) {
        const request = {
            Where: `EmpId == "${branchId}" && Brn.ACMCA_Brn.Any()`,
        };

        return this.apiService.post<{ Items: Branch[] }>('search/employee_branch', request).pipe(
            map((response) => {
                const branches = response?.Items ?? [];

                branches.forEach((branch) => {
                    if (branch.BrnId === branchId) {
                        branch.checked = true;
                        branch.defaultBranch = true;
                    }
                });

                return branches;
            })
        );
    }
}
