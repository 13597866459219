import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfigService } from './config.service';

@Injectable({
    providedIn: 'root',
})
export class AlertService {
    ok = 'OK';

    constructor(
        private sb: MatSnackBar,
        private configService: ConfigService
    ) {}

    success(message: string, actionLabel?: string) {
        console.log(`%c${message}`, 'color: green');

        return this.sb.open(message, actionLabel ?? this.ok, {
            panelClass: 'success-alert',
            duration: this.getAlertTimeout(),
        });
    }

    log(message: string) {
        console.info(message);
        return this.sb.open(message, this.ok, { panelClass: 'info-alert', duration: this.getAlertTimeout() });
    }

    warning(message: string) {
        console.warn(message);
        return this.sb.open(message, this.ok, { panelClass: 'warning-alert', duration: this.getAlertTimeout() });
    }

    error<T extends Error>(message: string, error: T | undefined | null = null) {
        console.error(`ErrorMsg: ${message}${error ? `\r\nError: ${error.message}` : ''}`);
        return this.sb.open(message, this.ok, {
            panelClass: 'error-alert',
            duration: this.getAlertTimeout(),
            data: error,
        });
    }

    private getAlertTimeout(): number {
        return this.configService.config.applicationSettings.alertTimeoutSeconds * 1000;
    }
}
