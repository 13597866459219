import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, switchMap } from 'rxjs';
import { ApiService, ConfigService } from '../../common/services';
import { MessageHistoryItem, MessageHistoryItemUpdate } from '../interfaces/MessageHistoryItem';

export interface SearchParams {
    customerId: string;
    sortBy?: { active: string; direction: 'asc' | 'desc' | '' };

    Name: string | null;
    EmailWork: string | null;
    InvoiceType: string | null;
    DocumentIdList: string | null;
    Status: string | null;
    Error: string | null;
}

@Injectable({
    providedIn: 'root',
})
export class DeliveryStatusService {
    constructor(
        private apiService: ApiService,
        private http: HttpClient,
        private configService: ConfigService
    ) {}

    listDeliveryStatuses(searchParams: SearchParams): Observable<MessageHistoryItem[]> {
        return this.apiService
            .post<{
                Items: MessageHistoryItem[];
            }>('search/doc_email_his', this.getEmailHistoryRequestBody(searchParams))
            .pipe(map((response) => response.Items ?? []));
    }

    deleteMessage(message: MessageHistoryItem): Observable<void> {
        return this.getMessage(message.ItmId).pipe(
            switchMap((response) => {
                return this.apiService.delete<void>(`doc_email_his/${message.ItmId}`, response);
            })
        );
    }

    updateMessage(message: MessageHistoryItemUpdate) {
        return this.apiService.put<MessageHistoryItemUpdate>(`doc_email_his_itm/${message.Id}`, message);
    }

    getMessage(messageId: MessageHistoryItem['ItmId']): Observable<MessageHistoryItem> {
        return this.apiService.get<MessageHistoryItem>(`doc_email_his/${messageId}`);
    }

    getAttachment(attachmentId: number): Observable<Blob> {
        return this.http.get(
            `${this.configService.config.apiSettings.serviceUrl}doc_email_his_att_file/${attachmentId}`,
            { responseType: 'blob' }
        );
    }

    private getEmailHistoryRequestBody({ customerId, sortBy, ...rest }: SearchParams) {
        const filterClause = this.generateFilterClause(rest);

        return {
            Where: `${filterClause || ' 1=1 '} AND CusId = "${customerId}"`,
            OrderBy: sortBy ? [sortBy.active, sortBy.direction.toLocaleUpperCase()].join(' ') : 'DateSent DESC',
            Limit: 99999,
            Skip: 0,
            Includes: null,
        };
    }

    private generateFilterClause(searchParams: Omit<SearchParams, 'customerId' | 'sortBy'>): string {
        return Object.entries(searchParams).reduce((whereClause, [key, value]) => {
            if (!value) {
                return whereClause;
            }

            return [whereClause, `${key}.ToString().ToLower().Contains("${value.trim().toLowerCase()}")`]
                .filter(Boolean)
                .join(' && ');
        }, '');
    }
}
