import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiService } from '../../common/services';

export type ErrorResponse = ErrorItemDto[];

export interface ErrorItemDto {
    CusId: string;
    Name: string;
    Contact: string;
    Email: string;
    DocumentId: string;
    DateSent: string;
    Error: string;
    Attempts: number;
}

@Injectable({
    providedIn: 'root',
})
export class ErrorService {
    private customerId$ = new Subject<string>();

    constructor(private apiService: ApiService) {}

    public handleGetGlobalErrorHistory(): Observable<ErrorResponse> {
        return this.getGlobalErrorHistory();
    }

    public selectedCustomerIdObservable() {
        return this.customerId$.asObservable();
    }

    public nextSelectedCustomerId(cusId: string) {
        this.customerId$.next(cusId);
    }

    private getGlobalErrorHistory(): Observable<ErrorResponse> {
        return this.apiService.get('doc_email_his_itm_errors');
    }
}
