<div class="login">
    <div class="container">
        <div class="login-right-side">
            <div class="login-messagebox">
                <span class="green-text-login">{{ title }}</span>
                <span class="white-text-login">{{ subtitle }}</span>
            </div>
        </div>

        <form
            #loginForm="ngForm"
            autocomplete="off"
            class="login-form"
            (ngSubmit)="loginButton$.next()"
        >
            <div class="form-group">
                <mat-label>Employee ID</mat-label>
                <mat-form-field>
                    <input
                        matInput
                        type="text"
                        name="employeeId"
                        class="form-control"
                        [(ngModel)]="employeeId"
                        (ngModelChange)="employeeId$.next($event)"
                        autocomplete="off"
                        required
                    />
                </mat-form-field>
            </div>

            <div class="form-group">
                <mat-label>Password</mat-label>
                <mat-form-field>
                    <input
                        matInput
                        type="password"
                        name="customer"
                        name="password"
                        class="form-control"
                        [(ngModel)]="password"
                        autocomplete="off"
                        required
                    />
                </mat-form-field>
            </div>

            <div class="form-group">
                <mat-label>Branch</mat-label>

                <mat-form-field class="login-select">
                    <mat-select
                        name="loginSelect"
                        [(ngModel)]="loginBranch"
                        [disabled]="branches.length === 0"
                        panelClass="login-select-panel"
                        required
                    >
                        @for (branch of branches; track branch.Id) {
                            <mat-option [value]="branch">{{ branch.Label }}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="login-button">
                <button
                    type="submit"
                    mat-flat-button
                    [disabled]="loginForm.invalid || isLoading()"
                >
                    Log In
                </button>
            </div>

            <div
                class="error-login"
                *ngIf="errorMessage"
            >
                <mat-icon>error</mat-icon>
                <span>{{ errorMessage }}</span>
            </div>
        </form>
    </div>
</div>

<div
    [hidden]="(showLoader$ | async) === false"
    class="loader"
>
    <mat-spinner />
</div>
