import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Customer } from '../interfaces';
import { ApiService } from '../services';

@Injectable({
    providedIn: 'root',
})
export class CustomerService {
    constructor(private apiService: ApiService) {}

    searchCustomer(searchPhrase: string): Observable<Customer[]> {
        const payload = {
            Where: `Inactive == 0 && (Name.Contains("${searchPhrase}") || Id.Contains("${searchPhrase}"))`,
            OrderBy: '',
            Limit: 15,
            Skip: 0,
        };

        return this.apiService
            .post<{ Items: Customer[] }>('search/customer', payload)
            .pipe(map((response) => response.Items));
    }

    updateCustomer(customer: Customer): Observable<Customer> {
        return this.apiService.put<Customer>(`customer/${customer.Id}`, customer);
    }
}
