import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { ConfigService } from '../../../common/services';
import { AsyncPipe } from '@angular/common';

@UntilDestroy()
@Component({
    selector: 'app-about',
    standalone: true,
    imports: [MatButtonModule, AsyncPipe],
    templateUrl: './about.component.html',
    styleUrl: './about.component.scss',
})
export class AboutComponent {
    version = '';
    excedeApiVersion: Observable<string>;
    copyYear = new Date().getFullYear();

    constructor(private configService: ConfigService) {
        this.version = this.configService.config.version;

        this.excedeApiVersion = this.configService.getApiVersion();
    }
}
