import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, Observable, switchMap, take } from 'rxjs';
import { AuthService } from '../../auth/services/auth.service';
import { SessionService } from '../../auth/services/session.service';
import { isNullOrUndefinedOrBlank } from '../utils';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    private refreshTokenInProgress = false;
    private refreshTokenSubject = new BehaviorSubject<string | null>(null);

    constructor(
        private sessionService: SessionService,
        private authService: AuthService
    ) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if ((request.url.endsWith('token') && request.method === 'POST') || request.url.endsWith('config.json')) {
            return next.handle(request);
        }

        const accessExpired = this.sessionService.isTokenExpired();

        if (!accessExpired) {
            return next.handle(this.addHeaders(request));
        }

        if (!this.refreshTokenInProgress) {
            this.refreshTokenInProgress = true;
            this.refreshTokenSubject.next(null);

            return this.authService.requestUserToken().pipe(
                switchMap((response) => {
                    const token = response.token;
                    this.sessionService.setToken(token, response.token_expires);

                    this.refreshTokenInProgress = false;
                    this.refreshTokenSubject.next(token);

                    return next.handle(this.addHeaders(request));
                })
            );
        }

        return this.refreshTokenSubject.pipe(
            filter((result) => result !== null),
            take(1),
            switchMap(() => next.handle(this.addHeaders(request)))
        );
    }

    private addHeaders(authReq: HttpRequest<unknown>) {
        if (isNullOrUndefinedOrBlank(this.sessionService.token)) return authReq;

        if (authReq.method === 'POST' || authReq.method === 'PUT') {
            authReq = authReq.clone({
                setHeaders: {
                    Authorization: `bearer ${this.sessionService.token}`,
                    Accept: 'application/json',
                },
            });
        } else if (authReq.method === 'GET') {
            authReq = authReq.clone({
                setHeaders: {
                    Authorization: `bearer ${this.sessionService.token}`,
                    Accept: 'application/json',
                    'Cache-Control': 'no-cache',
                    Pragma: 'no-cache',
                    Expires: '-1',
                },
            });
        } else if (authReq.method === 'DELETE') {
            authReq = authReq.clone({
                setHeaders: {
                    Authorization: `bearer ${this.sessionService.token}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            });
        }

        return authReq;
    }
}
