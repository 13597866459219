import { AsyncPipe, CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs';
import { AuthService } from '../../../auth/services/auth.service';
import { SessionService } from '../../../auth/services/session.service';
import { SvgIconRendererComponent } from '../svg-icon-renderer/svg-icon-renderer.component';

@UntilDestroy()
@Component({
    selector: 'app-standalone-navigation',
    templateUrl: './standalone-navigation.component.html',
    styleUrl: './standalone-navigation.component.scss',
    standalone: true,
    imports: [
        CommonModule,
        MatAccordion,
        MatExpansionModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatListModule,
        MatIconModule,
        MatMenuModule,
        AsyncPipe,
        SvgIconRendererComponent,
        RouterLink,
        RouterLinkActive,
    ],
})
export class StandaloneNavigationComponent {
    constructor(
        public sessionService: SessionService,
        private authService: AuthService,
        private router: Router
    ) {}

    onLogout() {
        this.authService
            .logout()
            .pipe(
                tap({
                    complete: () => {
                        void this.router.navigateByUrl('/login');
                    },
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }
}
