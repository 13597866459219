import { CommonModule, formatCurrency, formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, TrackByFunction } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormField } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatOption, MatSelect } from '@angular/material/select';
import { MatSortModule, Sort } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Subject, tap } from 'rxjs';
import { constants } from '../../../app-constants';
import { SafeHtmlPipe } from '../../pipes';
import { isNullOrUndefinedOrBlank } from '../../utils';
import { Column } from './Column';
import { ItemChangedEvent } from './ItemChangedEvent';

export type GridOptions = Partial<{
    editMode: boolean;
    hideAdd: boolean;
    showFooterRow: boolean;
}>;

@UntilDestroy()
@Component({
    standalone: true,
    selector: 'app-edit-grid',
    imports: [
        CommonModule,
        FormsModule,
        MatIcon,
        MatButtonModule,
        MatCheckbox,
        MatMenuModule,
        MatFormField,
        MatAutocompleteModule,
        MatSelect,
        MatOption,
        MatInputModule,
        MatDatepickerModule,
        MatTableModule,
        MatSortModule,
        SafeHtmlPipe,
    ],
    templateUrl: './edit-grid.component.html',
    styleUrls: ['./edit-grid.component.css'],
})
export class EditGridComponent<RowType extends object & { editMode?: boolean }> implements OnInit {
    @Input({ required: true })
    columns$!: Subject<Column<RowType>[]>;
    @Input({ required: true })
    gridData!: RowType[];

    @Input()
    cellTemplates!: { [key in Column<RowType>['field']]?: TemplateRef<unknown> };
    @Input()
    headerTemplates!: { [key in Column<RowType>['field']]?: TemplateRef<unknown> };
    @Input()
    noDataTemplate?: TemplateRef<unknown>;

    @Input() summaryRow?: RowType = undefined;
    @Input() gridOptions?: GridOptions = undefined;
    @Input() editMode$ = new BehaviorSubject(false);

    @Output() addNewRow = new EventEmitter<void>();
    @Output() rowClicked = new EventEmitter<RowType>();
    @Output() sortChanged = new EventEmitter<Sort>();
    @Output() itemChanged = new EventEmitter<ItemChangedEvent<RowType>>();

    _columns: Column<RowType>[] = [];
    displayedColumns: Column<RowType>['field'][] = [];

    editMode = false;

    dateFormats = ['MM/dd/yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
    dateFormat = this.dateFormats[0];
    trackByFn: TrackByFunction<Column<RowType>> = (index, item) => item.field;

    ngOnInit(): void {
        this.columns$
            .pipe(
                tap((columns) => {
                    this._columns = columns;

                    this.displayedColumns = this._columns.map((c) => c.field);
                }),
                untilDestroyed(this)
            )
            .subscribe();

        this.editMode$.pipe(untilDestroyed(this)).subscribe((mode) => {
            this.editMode = mode;
        });
    }

    filterColumns = (column: { editOnly?: boolean }) => {
        return this.gridOptions?.editMode ? true : !column.editOnly;
    };

    addRowClick() {
        this.addNewRow.emit();
    }

    itemChange(row: RowType, colName: ItemChangedEvent<RowType>['colName'], event: ItemChangedEvent<RowType>['event']) {
        this.itemChanged.next({ row: row, colName: colName, event: event });
    }

    renderField(row: RowType, col: Column<RowType>) {
        let renderValue = col.field.includes('actions') ? null : row[col.field as keyof RowType];

        // @ts-expect-error ts(2345)
        if (isNullOrUndefinedOrBlank(renderValue)) {
            return renderValue;
        }

        switch (col.formatFilter) {
            case 'currency':
                // @ts-expect-error ts(2322)
                renderValue = formatCurrency(Number(renderValue), constants.Locale, constants.CurrencySymbol);
                break;
            case 'datetime':
                // @ts-expect-error ts(2322)
                renderValue = formatDate(renderValue, this.dateFormat, constants.Locale);
                break;
        }

        return renderValue;
    }
}
