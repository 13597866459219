import { Injectable } from '@angular/core';
import { map, Observable, switchMap } from 'rxjs';
import { ApiService } from '../../common/services';
import { Contact } from '../interfaces';
import { ContactDeliverySettings, DeliverySettings } from '../interfaces/Contact';

type Entity<T> = T & { Id: string | number };

@Injectable({
    providedIn: 'root',
})
export class ContactsService {
    constructor(private apiService: ApiService) {}

    createContactDeliverySettings(contact: DeliverySettings): Observable<Contact> {
        return this.apiService.post<Contact, DeliverySettings>('doc_email', contact);
    }

    listCustomerContacts(customerId: string): Observable<ContactDeliverySettings[]> {
        return this.apiService
            .get<ContactDeliverySettings[]>(`doc_email/customer_contacts/${customerId}`)
            .pipe(map((response) => (Array.isArray(response) ? response : [])));
    }

    updateContactDeliverySettings(contact: ContactDeliverySettings) {
        return this.getContact(contact.ConId).pipe(
            switchMap((contactEntity) => {
                const updatedContact = { ...contactEntity, ...contact, Typ: contact.Title };
                return this.updateContact(updatedContact);
            }),
            switchMap(() => this.updateDeliverySettings({ ...contact, Id: contact.ItmId }))
        );
    }

    getContact(contactId: string | number): Observable<Entity<Contact>> {
        return this.apiService.get<Entity<Contact>>(`customer_contact/${contactId}`);
    }

    updateContact(contact: Entity<Contact>): Observable<Entity<Contact>> {
        return this.apiService.put<Entity<Contact>>(`customer_contact/${contact.Id}`, contact);
    }

    getDeliverySettings(contactId: string | number): Observable<Entity<DeliverySettings>> {
        return this.apiService.get<Entity<DeliverySettings>>(`doc_email/${contactId}`);
    }

    updateDeliverySettings(contact: Entity<DeliverySettings>): Observable<Entity<DeliverySettings>> {
        return this.apiService.put<Entity<DeliverySettings>>(`doc_email/${contact.Id}`, contact);
    }
}
