import { Column } from '../../../common/components/edit-grid/Column';
import { ScheduleItem } from '../../interfaces/ScheduleItem';

export const initialColumns: Column<ScheduleItem>[] = [
    {
        field: 'actionsStart',
        headerName: '',
        inputType: 'template',
        cellTemplate: null,
        sticky: 'start',
    },
    {
        field: 'Name',
        headerName: 'Schedule Name',
        inputType: 'text',
        required: true,
        viewStyle: {
            width: '150px',
        },
    },
    {
        field: 'Frequency',
        headerName: 'Frequency',
        inputType: 'select',
        selectOptions: [
            { Name: 'One Time', Id: 1 },
            { Name: 'Minutes', Id: 2 },
            { Name: 'Hourly', Id: 3 },
            { Name: 'Daily', Id: 4 },
            { Name: 'Weekly', Id: 5 },
            { Name: 'Monthly (On First)', Id: 6 },
            { Name: 'Monthly (On Last)', Id: 7 },
            { Name: 'Monthly (On Dates)', Id: 8 },
        ],
    },
    {
        field: 'Value',
        headerName: 'Value',
        inputType: 'number',
        editStyle: {
            width: '70px',
        },
    },
    {
        field: 'DateStart',
        headerName: 'Date Start',
        inputType: 'datetime',
        editStyle: {
            width: '150px',
        },
    },
    {
        field: 'IsDefault',
        headerName: 'Default',
        inputType: 'checkbox',
        editStyle: {
            width: '80px',
            textAlign: 'center',
        },
        viewStyle: {
            textAlign: 'center',
        },
    },
    {
        field: 'DateLast',
        headerName: 'Last',
        inputType: 'template',
        cellTemplate: null,
    },
    {
        field: 'DateNext',
        headerName: 'Next',
        inputType: 'template',
        cellTemplate: null,
    },
    {
        field: 'actionsEnd',
        headerName: '',
        inputType: 'template',
        cellTemplate: null,
        sticky: 'end',
        editOnly: true,
    },
];
