import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    GuardResult,
    MaybeAsync,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { forkJoin, from, map, Observable, of, switchMap } from 'rxjs';
import { Branch, Employee } from '../../common/interfaces';
import { ApiService } from '../../common/services';
import {
    BRANCH_COOKIE_KEY,
    isNullOrUndefined,
    isNullOrUndefinedOrBlank,
    TEST_KIOSK_MODE_COOKIE_KEY,
    USER_COOKIE_KEY,
} from '../../common/utils';
import { SessionService } from '../services/session.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private sessionService: SessionService,
        private cookieService: CookieService,
        private apiService: ApiService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const url = state.url;

        if (['/login', '.svg'].includes(url) || this.sessionService.isUserLoggedIn) {
            return true;
        }

        const userCookie = this.cookieService.get(USER_COOKIE_KEY);
        const branchCookie = this.cookieService.get(BRANCH_COOKIE_KEY);
        const testingCookie = JSON.parse<boolean>(this.cookieService.get(TEST_KIOSK_MODE_COOKIE_KEY) || 'false');

        this.sessionService.setKioskMode(!isNullOrUndefined(window.cefQuery) || !!testingCookie);

        if (!isNullOrUndefinedOrBlank(userCookie) && !isNullOrUndefinedOrBlank(branchCookie)) {
            const loggedInUser = JSON.parse<Employee>(userCookie);
            const loggedInBranch = JSON.parse<Branch>(branchCookie);

            return this.sessionService
                .loginUser(loggedInUser, loggedInBranch)
                .pipe(
                    switchMap((loginResult) =>
                        this.sessionService.isEmployeeAdmin(loggedInUser.Id).pipe(map(() => loginResult))
                    )
                );
        }

        if (this.sessionService.kioskMode$.value && !this.sessionService.isUserLoggedIn) {
            return this.bypassLogin();
        }

        this.sessionService.redirectPath = url;
        return this.router.navigate(['/login']);
    }

    private bypassLogin(): MaybeAsync<GuardResult> {
        return new Observable((observer) =>
            window.cefQuery({
                request: '2000',
                onSuccess: (token) => {
                    if (this.isValidToken(token)) {
                        this.sessionService.setToken(token, new Date(new Date().setHours(new Date().getHours() + 1)));

                        observer.next(true);
                        return;
                    }

                    console.error('Kiosk Mode failed: Token is not valid');
                    observer.next(false);
                },
                onFailure: (errorCode, errorMessage) => {
                    console.error(`Kiosk Mode failed: ${errorCode} - ${errorMessage}`);
                    observer.next(false);
                },
            })
        ).pipe(
            switchMap((result) => {
                if (!result) {
                    return of(false);
                }

                return this.apiService
                    .get<{ authenticated: boolean; branch_id: string; alternate_subject: string } | null>('token', {
                        headers: {
                            Authorization: 'bearer ' + this.sessionService.token,
                            Accept: 'application/json',
                            'Cache-Control': 'no-cache',
                        },
                    })
                    .pipe(
                        switchMap((response) => {
                            if (!response?.authenticated) {
                                console.error('Kiosk Mode failed: Token not authenticated');
                                return of([null, null]);
                            }

                            return forkJoin([
                                this.apiService.get<Employee>(`employee/${response.alternate_subject}`),
                                this.apiService.get<Branch>(`branch/${response.branch_id}`),
                            ]);
                        }),
                        switchMap(([employee, branch]) => {
                            if (!branch || !employee) {
                                return of(false);
                            }

                            return this.sessionService
                                .loginUser(employee, branch)
                                .pipe(switchMap(() => from(this.router.navigate(['/contacts']))));
                        })
                    );
            }),
            switchMap((res) => {
                if (!res) {
                    return this.redirectToLogin();
                }

                return of(res);
            })
        );
    }

    private redirectToLogin() {
        if (this.sessionService.kioskMode$.value)
            this.sessionService.setSsoErrorMessage(
                'Single Sign On has not been configured for this branch.  Please contact your System Administrator.'
            );
        else {
            this.sessionService.setSsoErrorMessage(null);
        }

        this.sessionService.setKioskMode(false);
        return from(this.router.navigate(['/login']));
    }

    private isValidToken(token: string) {
        let validToken = false;

        const base64Url = token.split('.')[1];
        if (!isNullOrUndefined(base64Url)) {
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            if (!isNullOrUndefined(base64)) {
                const decodedToken = JSON.parse<{ sub: string } | null>(window.atob(base64));
                if (!isNullOrUndefined(decodedToken) && !isNullOrUndefined(decodedToken.sub)) {
                    validToken = true;
                }
            }
        }

        return validToken;
    }
}
