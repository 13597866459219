import { CommonModule, formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
    BehaviorSubject,
    combineLatest,
    filter,
    forkJoin,
    map,
    Observable,
    of,
    ReplaySubject,
    switchMap,
    tap,
} from 'rxjs';
import { constants } from '../../../app-constants';
import {
    Column,
    ConfirmationDialogComponent,
    EditGridComponent,
    SvgIconRendererComponent,
} from '../../../common/components';
import { Customer } from '../../../common/interfaces';
import { AlertService } from '../../../common/services';
import { ScheduleItem as ScheduleItemDTO } from '../../interfaces/ScheduleItem';
import { ScheduleService } from '../../services/schedule.service';
import { initialColumns } from './initialColumns';

const DateFormat = 'MM/dd/yyyy hh:mm a';

type ScheduleItem = ScheduleItemDTO & { editMode: boolean };

@UntilDestroy()
@Component({
    selector: 'app-schedules',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        EditGridComponent,
        MatButtonModule,
        MatIcon,
        MatDivider,
        MatProgressSpinner,
        SvgIconRendererComponent,
    ],
    templateUrl: './schedules.component.html',
    styleUrl: './schedules.component.scss',
})
export class SchedulesComponent implements OnInit {
    @Input({ required: true })
    selectedCustomer!: Observable<Customer | null>;
    _selectedCustomer: Customer | null = null;

    areSchedulesLoading = false;
    schedules: ScheduleItem[] = [];
    schedulesMemo: ScheduleItem[] = [];

    columns = new BehaviorSubject<Column<ScheduleItem>[]>(initialColumns);
    refreshSchedules = new ReplaySubject<void>(1);

    constructor(
        private scheduleService: ScheduleService,
        private alertService: AlertService,
        private dialog: MatDialog,
        private cdr: ChangeDetectorRef
    ) {
        this.refreshSchedules.next();
    }

    ngOnInit() {
        combineLatest([this.selectedCustomer, this.refreshSchedules])
            .pipe(
                map(([customer]) => customer),
                filter((customer) => !!customer),
                tap((customer) => {
                    this._selectedCustomer = customer;
                }),
                tap(() => (this.areSchedulesLoading = true)),
                switchMap(() => this.scheduleService.listSchedules()),
                tap((response) => {
                    this.schedules = response.map((item) => ({
                        ...item,
                        DateStart: formatDate(item.DateStart, DateFormat, constants.Locale),
                        DateNext: item.DateNext
                            ? formatDate(item.DateNext, DateFormat, constants.Locale)
                            : item.DateNext,
                        DateLast: item.DateLast
                            ? formatDate(item.DateLast, DateFormat, constants.Locale)
                            : item.DateLast,
                        editMode: false,
                    }));

                    this.schedulesMemo = this.schedules.map((contact) => ({ ...contact }));

                    this.cdr.detectChanges();
                }),
                tap(this.turnOffLoadingObserver()),
                untilDestroyed(this)
            )
            .subscribe();
    }

    editRow(schedule: ScheduleItem) {
        this.schedules.forEach((s) => {
            if (s.Id === schedule.Id) {
                schedule.editMode = true;
                return;
            }

            this.cancelEditRow(s);
        });
    }

    addNewSchedule() {
        if (this.schedules.some((s) => s.Id === 0)) {
            return;
        }

        const newSchedule: ScheduleItem = {
            Id: 0,
            SrcAppId: 1,
            Name: 'New Schedule',
            Frequency: 1,
            Value: 0,
            DateStart: formatDate(new Date(), DateFormat, constants.Locale),
            DateNext: null,
            DateLast: null,
            IsDefault: false,
            editMode: false,
        };

        this.schedules.push(newSchedule);
        this.editRow(newSchedule);
    }

    saveSchedule(schedule: ScheduleItem) {
        // TODO: Save prevention should be considered when form is invalid
        if (!schedule.Name) {
            return;
        }

        const defaultSchedulePresent = this.schedules.find((s) => s.IsDefault);
        if (!schedule.IsDefault && !defaultSchedulePresent) {
            this.alertService.error('Please select a default schedule first');
            this.cancelEditRow(schedule);
            return;
        }

        of(true)
            .pipe(
                tap(() => {
                    this.areSchedulesLoading = true;
                }),
                switchMap(() =>
                    forkJoin(
                        this.schedules
                            .filter((s) => s.IsDefault)
                            .map((s) => {
                                if (s.Id === schedule.Id || !schedule.IsDefault) {
                                    return of(true);
                                }
                                return this.scheduleService.updateSchedule({ ...s, IsDefault: false });
                            })
                    )
                ),
                switchMap(() =>
                    schedule.Id === 0
                        ? this.scheduleService.createSchedule(schedule)
                        : this.scheduleService.updateSchedule(schedule)
                ),
                tap(this.turnOffLoadingObserver()),
                tap(() => this.refreshSchedules.next()),
                untilDestroyed(this)
            )
            .subscribe();
    }

    cancelEditRow(schedule: ScheduleItem) {
        if (schedule.Id === 0) {
            this.schedules = this.schedules.filter((s) => s.Id !== 0);
            return;
        }

        const scheduleIndex = this.schedules.findIndex((c) => c.Id === schedule.Id);

        if (scheduleIndex !== -1) {
            this.schedules[scheduleIndex] = {
                ...this.schedules[scheduleIndex],
                ...this.schedulesMemo[scheduleIndex],
                editMode: false,
            };
            this.schedules = [...this.schedules];
        }

        this.cdr.detectChanges();
    }

    deleteSchedule(schedule: ScheduleItem) {
        if (schedule.IsDefault) {
            this.alertService.error('Unable to delete schedule while set to default');
            return;
        }

        if (schedule.DOC_EMAIL_Sched && schedule.DOC_EMAIL_Sched.length > 0) {
            this.alertService.error('This schedule is assigned to contact(s) and cannot be removed.');
            return;
        }

        if (schedule.Id === 0) {
            this.schedules = this.schedules.filter((s) => s.Id !== 0);
            return;
        }

        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {
                bodyText: `
                    Please confirm that you want to <strong>delete this schedule.</strong>
                    <h4>${schedule.Name} (${schedule.Id})</h4>
                `,
            },
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((res) => !!res),
                tap(() => {
                    this.areSchedulesLoading = true;

                    this.cdr.detectChanges();
                }),
                switchMap(() => this.scheduleService.deleteSchedule(schedule)),
                tap(this.turnOffLoadingObserver()),
                tap(() => this.refreshSchedules.next()),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private turnOffLoadingObserver() {
        return {
            next: () => this.turnOffLoading(),
            error: () => this.turnOffLoading(),
        };
    }

    private turnOffLoading() {
        this.areSchedulesLoading = false;
        this.cdr.detectChanges();
    }
}
