import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import {
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router,
    RouterOutlet,
} from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs';
import { fadeAnimation } from '../../animations';
import { LoaderService } from '../../services';
import { StandaloneNavigationComponent } from '../standalone-navigation/standalone-navigation.component';

@UntilDestroy()
@Component({
    selector: 'app-layout',
    standalone: true,
    imports: [CommonModule, RouterOutlet, MatProgressSpinner, StandaloneNavigationComponent],
    templateUrl: './layout.component.html',
    styleUrl: './layout.component.scss',
    animations: [fadeAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent {
    constructor(
        private router: Router,
        public loaderService: LoaderService
    ) {
        this.router.events
            .pipe(
                tap((event) => {
                    if (event instanceof NavigationStart) {
                        this.loaderService.show();
                    }

                    if (
                        event instanceof NavigationEnd ||
                        event instanceof NavigationError ||
                        event instanceof NavigationCancel
                    ) {
                        this.loaderService.hide();
                    }
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }
}
