<h3>About Excede Document Delivery</h3>

<div class="about-list">
    <dl class="about-item">
        <dt>Description</dt>
        <dd>
            Excede Document Delivery is an enhanced solution that enables sending invoices and AR statements via email.
        </dd>
    </dl>

    <dl class="about-item">
        <dt>By</dt>
        <dd>
            <span style="font-weight: bold">Procede Software</span>
            <a
                mat-button
                target="blank"
                href="http://www.procedesoftware.com"
                >www.procedesoftware.com</a
            >
        </dd>
    </dl>

    <dl class="about-item">
        <dt>Version</dt>
        <dd>
            <div>Excede Document Delivery {{ version }}</div>
            <div>Excede API {{ excedeApiVersion | async }}</div>
        </dd>
    </dl>

    <dl class="about-item">
        <dt>Compatibility</dt>
        <dd>Document Delivery runs embedded in Excede 10.1+</dd>
    </dl>

    <dl class="about-item">
        <dt>Copyright</dt>
        <dd>Copyright &copy; {{ copyYear }}. Procede Software. All Rights Reserved</dd>
    </dl>
</div>
