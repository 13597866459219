import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule, MatIconButton } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule, Sort } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
    BehaviorSubject,
    combineLatest,
    debounceTime,
    filter,
    forkJoin,
    map,
    Observable,
    of,
    ReplaySubject,
    switchMap,
    tap,
} from 'rxjs';
import { Column, ConfirmationDialogComponent } from '../../../common/components';
import { EditGridComponent } from '../../../common/components/edit-grid/edit-grid.component';
import { Customer } from '../../../common/interfaces';
import { MessageHistoryItem } from '../../interfaces/MessageHistoryItem';
import { DeliveryStatusService } from '../../services/delivery-status.service';
import { ViewEmailDialogComponent } from '../view-email-dialog/view-email-dialog.component';
import { initialColumns } from './initialColumns';

const AngularMaterialImport = [
    MatIconModule,
    MatIconButton,
    MatButtonModule,
    MatTooltipModule,
    MatProgressSpinner,
    MatDividerModule,
    MatCheckboxModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatSortModule,
];

const optionsMap: Record<'InvoiceType' | 'Status' | 'Error', { value: string; label: string }[]> = {
    InvoiceType: [
        { value: '', label: '' },
        { value: 'AR Statement', label: 'AR Statement' },
        { value: 'Fuel', label: 'Fuel' },
        { value: 'Lease Rental', label: 'Lease Rental' },
        { value: 'Multiple', label: 'Multiple' },
        { value: 'Parts', label: 'Parts' },
        { value: 'Service', label: 'Service' },
    ],
    Status: [
        { value: '', label: '' },
        { value: 'Sent', label: 'Sent' },
        { value: 'Pending', label: 'Resend Pending' },
    ],
    Error: [
        { value: '', label: '' },
        { value: 'Failed', label: 'Failed' },
        { value: 'Not Delivered', label: 'Not Delivered' },
    ],
};

@UntilDestroy()
@Component({
    selector: 'app-delivery-status',
    standalone: true,
    imports: [CommonModule, FormsModule, ReactiveFormsModule, ...AngularMaterialImport, EditGridComponent],
    templateUrl: './delivery-status.component.html',
    styleUrl: './delivery-status.component.scss',
})
export class DeliveryStatusComponent implements OnInit {
    @Input({ required: true })
    selectedCustomer!: Observable<Customer | null>;

    areMessagesLoading = false;
    messageHistoryItems: MessageHistoryItem[] = [];

    columns = new BehaviorSubject<Column<MessageHistoryItem>[]>(initialColumns);
    refreshMessageHistory = new ReplaySubject<void>(1);

    customerId = '';
    searchParamsControl = new FormGroup({
        Name: new FormControl(''),
        EmailWork: new FormControl(''),
        InvoiceType: new FormControl(''),
        DocumentIdList: new FormControl(''),
        Status: new FormControl(''),
        Error: new FormControl(''),
    });

    sort: Sort | undefined = {
        active: 'DateSent',
        direction: 'desc',
    };

    constructor(
        private deliveryStatusService: DeliveryStatusService,
        private dialog: MatDialog,
        private cdr: ChangeDetectorRef
    ) {
        this.refreshMessageHistory.next();
    }

    ngOnInit() {
        combineLatest([this.selectedCustomer, this.refreshMessageHistory])
            .pipe(
                map(([customer]) => customer),
                filter((customer) => !!customer),
                tap((customer) => {
                    this.customerId = customer.Id;
                }),
                tap(() => (this.areMessagesLoading = true)),
                switchMap(() =>
                    this.deliveryStatusService.listDeliveryStatuses({
                        ...this.searchParamsControl.getRawValue(),
                        sortBy: this.sort,
                        customerId: this.customerId,
                    })
                ),
                tap((response) => {
                    this.messageHistoryItems = response;

                    this.cdr.detectChanges();
                }),
                tap(this.turnOffLoadingObserver()),
                untilDestroyed(this)
            )
            .subscribe();

        this.searchParamsControl.valueChanges
            .pipe(
                debounceTime(300),
                tap(() => {
                    this.refreshMessageHistory.next();
                    this.cdr.detectChanges();
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    sortData($event: Sort) {
        this.sort = $event.direction ? $event : undefined;
        this.refreshMessageHistory.next();
    }

    getOptionsMap(key: 'InvoiceType' | 'Status' | 'Error') {
        return optionsMap[key];
    }

    getInvoicesTitle(row: MessageHistoryItem) {
        return row.DocumentIdList.join('<br/>');
    }

    openMessage(row: MessageHistoryItem) {
        const dialogRef = this.dialog.open(ViewEmailDialogComponent, {
            data: row,
            minWidth: '640px',
            maxWidth: '70vw',
            maxHeight: '80vh',
            disableClose: true,
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((res) => !!res),
                tap(() => {
                    this.areMessagesLoading = true;
                    this.cdr.detectChanges();
                }),
                switchMap(() => this.deliveryStatusService.updateMessage(this.createResendBody(row))),
                tap(this.turnOffLoadingObserver()),
                tap(() => this.refreshMessageHistory.next()),
                untilDestroyed(this)
            )
            .subscribe();
    }

    resendEmails() {
        of(true)
            .pipe(
                tap(() => {
                    this.areMessagesLoading = true;

                    this.cdr.detectChanges();
                }),
                switchMap(() =>
                    forkJoin(
                        this.messageHistoryItems
                            .filter((row) => row.Resend)
                            .map((row) => this.deliveryStatusService.updateMessage(this.createResendBody(row)))
                    )
                ),
                tap(this.turnOffLoadingObserver()),
                tap(() => this.refreshMessageHistory.next()),
                untilDestroyed(this)
            )
            .subscribe();
    }

    deleteMessage(row: MessageHistoryItem) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {
                bodyText: `
                    Please confirm that you want to <strong>delete this email record.</strong>
                `,
            },
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((res) => !!res),
                tap(() => {
                    this.areMessagesLoading = true;

                    this.cdr.detectChanges();
                }),
                switchMap(() => this.deliveryStatusService.deleteMessage(row)),
                tap(this.turnOffLoadingObserver()),
                tap(() => this.refreshMessageHistory.next()),
                untilDestroyed(this)
            )
            .subscribe();
    }

    attachmentsClicked(row: MessageHistoryItem) {
        this.dialog.open(ConfirmationDialogComponent, {
            data: {
                title: 'Attachments',
                bodyText: this.getInvoicesTitle(row),
                confirmButtonLabel: 'Close',
                showCancelButton: false,
            },
        });
    }

    private createResendBody(row: MessageHistoryItem) {
        return {
            Id: row.HisItmId,
            HisId: row.ItmId,
            CusId: row.CusId,
            ConId: row.ConId,
            // TODO: Ask if below line is valid or just a leftover
            Status: row.Resend ? 'Resend Pending' : 'Resend Cancelled',
            Error: row.Error,
            Attempts: row.Attempts,
            Resend: row.Resend,
        };
    }

    private turnOffLoadingObserver() {
        return {
            next: () => this.turnOffLoading(),
            error: () => this.turnOffLoading(),
        };
    }

    private turnOffLoading() {
        this.areMessagesLoading = false;
        this.cdr.detectChanges();
    }
}
