import { APP_INITIALIZER, ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';

import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { routes } from './app.routes';

import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { angularMaterialProviders } from '../theme/angularMaterialProviders';
import { SessionService } from './auth/services/session.service';
import { ApiInterceptor } from './common/interceptors/api.interceptor';
import { ConfigService } from './common/services';

export const appConfig: ApplicationConfig = {
    providers: [
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideHttpClient(withInterceptorsFromDi()),
        {
            provide: APP_INITIALIZER,
            useFactory: (configService: ConfigService) => () => configService.loadConfig(),
            deps: [ConfigService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiInterceptor,
            multi: true,
        },
        provideRouter(routes, withComponentInputBinding()),
        provideAnimationsAsync(),
        ...angularMaterialProviders,

        SessionService,
    ],
};
