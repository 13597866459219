<div
    class="container"
    [class.opacity]="areErrorsLoading"
>
    <div class="container-header">
        <h2>Errors</h2>
    </div>

    <mat-divider />

    <div class="grid-container">
        <app-edit-grid
            [columns$]="columns"
            [gridData]="errors"
            [headerTemplates]="{
                DateSent: dateSentHeader,
            }"
            [cellTemplates]="{
                CustomerName: customerNameCell,
                DateSent: dateSentCell,
            }"
        />

        <ng-template
            #customerNameCell
            let-column
        >
            <a
                (click)="onErrorsCustomerClick(column)"
                (keydown)="onErrorsCustomerClick(column)"
                tabindex="1"
                >{{ column.CustomerName }}</a
            >
        </ng-template>

        <ng-template
            #dateSentHeader
            let-column
        >
            <span title="This is when the email was originally sent">Date Sent</span>
        </ng-template>

        <ng-template
            #dateSentCell
            let-column
        >
            <span title="This is when the email was originally sent">{{
                column.DateSent | date: 'MMM dd, yyyy h:mm a'
            }}</span>
        </ng-template>
    </div>
</div>

<div
    *ngIf="areErrorsLoading"
    class="loader absolute-center"
>
    <mat-spinner diameter="64" />
</div>
