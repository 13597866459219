export const constants = {
    Locale: 'en-US',
    CurrencySymbol: '$',
    MasterBranch: '100',
    True: 'true',
    False: 'false',
    BranchSettings: {
        Fax: 'documentdelivery\\fax',
        Printer: 'documentdelivery\\printer',
        ARBranch: 'documentdelivery\\arbranch',
        AdminUsers: 'documentdelivery\\adminempid',
    },
    CompanyFormTypes: {
        ServiceOrder: 503,
        PartsOrder: 505,
        Customer: 515,
        LeaseRental: 523,
        FuelOrder: 635,
    },
    AppName: 'Document Delivery',
};
