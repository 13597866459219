import { Injectable } from '@angular/core';
import { Observable, forkJoin, of, throwError, map, switchMap, catchError } from 'rxjs';
import { ApiService } from '../../common/services';
import { BranchSettingsParentIcludedDTO } from '../../auth/interfaces/branch-settings';
import { constants } from '../../app-constants';

export type ArchiveTestType = 'ar_archive' | 'invoice_archives';

@Injectable({
    providedIn: 'root',
})
export class ArchiveService {
    constructor(private apiService: ApiService) {}

    public onTestApiArchivePermissions(type: ArchiveTestType): Observable<string[]> {
        if (type === 'ar_archive') {
            return this.onTestApiArArchivePermissions();
        }
        if (type === 'invoice_archives') {
            return this.onTestApiInvoiceArchivePermissions();
        }
        return throwError(() => new Error('Unrecognized test type!'));
    }

    private onTestApiArArchivePermissions(): Observable<string[]> {
        const requestIncludeBranches = {
            Includes: ['ParentItm'],
            Where: 'ParentItm.Typ == 1535 && Name.Contains("documentdelivery") && Name.Contains("arbranch")',
        };

        return this.apiService
            .post<{
                TotalItems: number;
                Items: BranchSettingsParentIcludedDTO[];
            }>('search/branch_setting', requestIncludeBranches)
            .pipe(
                switchMap((responseARBranch) => this.handleBranchSettingsResponse(responseARBranch)),
                catchError((err) => {
                    console.error('Error while testing AR archive permissions:', err);
                    return of([]);
                })
            );
    }

    private onTestApiInvoiceArchivePermissions(): Observable<string[]> {
        return this.testApiAllArchivePermissions().pipe(
            map((response) => this.extractErrorMessages(response)),
            catchError((err) => {
                console.error('Error while testing invoice archive permissions:', err);
                return of([]);
            })
        );
    }

    private handleBranchSettingsResponse(responseARBranch: {
        TotalItems: number;
        Items: BranchSettingsParentIcludedDTO[];
    }): Observable<string[]> {
        const errorMessages: string[] = [];

        if (responseARBranch.TotalItems === 0) {
            return of(errorMessages);
        }

        const arBranches = responseARBranch.Items.filter((item) => item.Valu && item.Valu.toLowerCase() === 'true').map(
            (response) => response.ParentItm.BrnId
        );

        const testRequests = arBranches.map((branchId) =>
            this.testApiArchivePermissions(branchId, constants.CompanyFormTypes.Customer)
        );

        return testRequests.length === 0
            ? of(errorMessages)
            : forkJoin(testRequests).pipe(
                  switchMap((responses) =>
                      this.processTestResponses(responses, errorMessages, responseARBranch.TotalItems)
                  )
              );
    }

    private processTestResponses(
        responses: { CanAccess: boolean; ErrorMessage: string }[],
        errorMessages: string[],
        totalItems: number
    ): Observable<string[]> {
        const newErrorMessages = this.extractErrorMessages(responses, errorMessages);

        if (totalItems === 0 || newErrorMessages.some((message) => message.includes('not found'))) {
            return this.testApiArchivePermissions(constants.MasterBranch, constants.CompanyFormTypes.Customer).pipe(
                map((response) => {
                    if (!response.CanAccess && !newErrorMessages.includes(response.ErrorMessage)) {
                        newErrorMessages.push(response.ErrorMessage);
                    }
                    return newErrorMessages;
                })
            );
        }
        return of(newErrorMessages);
    }

    private extractErrorMessages(
        response: { CanAccess: boolean; ErrorMessage: string }[] = [],
        errorMessages: string[] = []
    ): string[] {
        return [
            ...new Set([...errorMessages, ...response.filter((res) => !res.CanAccess).map((res) => res.ErrorMessage)]),
        ];
    }

    private testApiArchivePermissions(
        branchId: number | string,
        formTypeId: number
    ): Observable<{
        CanAccess: boolean;
        ErrorMessage: string;
    }> {
        return this.apiService
            .post<{
                CanAccess: boolean;
                ErrorMessage: string;
            }>(`company_form_validate_archive/1/${formTypeId}/${branchId}`, {})
            .pipe(
                catchError((err) => {
                    console.error('Error testing archive permissions for branch:', branchId, err);
                    return of({ CanAccess: false, ErrorMessage: 'Unknown error' });
                })
            );
    }

    private testApiAllArchivePermissions(): Observable<
        {
            CanAccess: boolean;
            ErrorMessage: string;
        }[]
    > {
        return this.apiService
            .post<
                {
                    CanAccess: boolean;
                    ErrorMessage: string;
                }[]
            >('company_form_validate_all_invoice_archive', {})
            .pipe(
                catchError((err) => {
                    console.error('Error testing all archive permissions:', err);
                    return of([]);
                })
            );
    }
}
