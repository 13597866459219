import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
    BehaviorSubject,
    Subject,
    catchError,
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    of,
    switchMap,
    tap,
} from 'rxjs';
import { Branch, Employee } from '../../../common/interfaces';
import { AlertService } from '../../../common/services/alert.service';
import { ApiService } from '../../../common/services/api.service';
import { BranchService } from '../../../common/services/branch.service';
import { isNullOrUndefinedOrBlank } from '../../../common/utils';
import { AuthService } from '../../services/auth.service';
import { SessionService } from '../../services/session.service';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@UntilDestroy()
@Component({
    standalone: true,
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
    imports: [
        CommonModule,
        FormsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatProgressSpinner,
        MatIconModule,
    ],
})
export class LoginComponent {
    showLoader$ = new BehaviorSubject<boolean>(false);
    employeeId$ = new Subject<string | null>();
    loginButton$ = new Subject<void>();

    employee: Employee | null = null;
    loginBranch: Branch | null = null;
    employeeId = '';
    password = '';

    branches: Branch[] = [];
    errorMessage = '';

    title = 'Welcome';
    subtitle = 'Please Log In';

    constructor(
        private sessionService: SessionService,
        private branchService: BranchService,
        private authService: AuthService,
        private apiService: ApiService,
        private alertService: AlertService,
        private router: Router
    ) {
        this.employeeId$
            .pipe(
                filter((empId) => !isNullOrUndefinedOrBlank(empId)),
                debounceTime(500),
                distinctUntilChanged(),
                switchMap((employeeId) => this.setEmployeeAndBranch(employeeId ?? '')),
                untilDestroyed(this)
            )
            .subscribe();

        this.loginButton$
            .pipe(
                tap(() => this.showLoader$.next(true)),
                tap(() => this.resetMessages()),
                switchMap(() =>
                    this.sessionService.isEmployeeAdmin(this.employee!.Id).pipe(
                        tap((isEmployeeAdmin) => {
                            if (!isEmployeeAdmin) {
                                this.errorMessage = 'Not Authorized to Use Application';
                                this.title = 'Oops!';
                                this.subtitle = 'Login Required';
                            }
                        })
                    )
                ),
                filter(Boolean),
                switchMap(() => this.loginEmployee(this.employee!, this.password, this.loginBranch!)),
                tap({
                    next: () => this.showLoader$.next(false),
                    error: () => this.showLoader$.next(false),
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    isLoading() {
        return !this.employee || !this.loginBranch;
    }

    private loginEmployee(employee: Employee, password: string, branch: Branch) {
        return this.authService.doAuth(employee, password, branch.BrnId).pipe(
            map((response) => {
                if (!response || !response.Authenticated) {
                    this.errorMessage = !response.AzureAdAuthRequired
                        ? 'Invalid Credentials'
                        : "Microsoft email doesn't match your employee record";

                    this.title = 'Oops!';
                    this.subtitle = 'Login Required';

                    this.showLoader$.next(false);
                    return false;
                }

                return true;
            }),
            filter(Boolean),
            switchMap(() => this.sessionService.loginUser(employee, branch)),
            tap(() => {
                void this.router.navigateByUrl('/contacts');
            })
        );
    }

    private setEmployeeAndBranch(employeeId: string) {
        return this.branchService.getBranchesForEmployeeId(employeeId).pipe(
            tap({
                next: (branches) => {
                    if (branches.length === 0) {
                        throw Error('No branches found');
                    }

                    this.branches = branches.map((branch) => {
                        branch.Label = `${branch.BrnId} - ${branch.Brn.Name}`;
                        return branch;
                    });
                },
            }),
            catchError(() => {
                this.alertService.error(`Can't load branches for Employee ID '${employeeId}'`);

                this.branches = [];

                return of(null);
            }),
            switchMap(() => this.apiService.get<Employee>(`employee/${employeeId}`)),
            tap({
                next: (response) => {
                    if (!response) {
                        return;
                    }

                    this.employee = response;

                    const loginBranch = this.branches.find((branch) => branch.BrnId === this.employee?.BrnId);
                    if (loginBranch) {
                        this.loginBranch = loginBranch;
                    }
                },
            })
        );
    }

    private resetMessages() {
        this.errorMessage = '';
        this.title = 'Welcome';
        this.subtitle = 'Please Log In';
    }
}
