import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { map, of, switchMap, tap } from 'rxjs';
import { Branch, Config, Employee } from '../../common/interfaces';
import { ApiService } from '../../common/services/api.service';
import { ConfigService } from '../../common/services/config.service';
import { BRANCH_COOKIE_KEY, USER_COOKIE_KEY } from '../../common/utils';
import { AzureService } from './azure.service';
import { SessionService } from './session.service';

export type AuthResponse = {
    Authenticated: boolean;
    DefaultBrnId: string;
    DefaultBranchName: string;
} & (
    | { AzureAdAuthRequired: true; AzureAdClientId: string; AzureAdTenantId: string }
    | { AzureAdAuthRequired: false; AzureAdClientId: null; AzureAdTenantId: null }
);

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(
        private apiService: ApiService,
        private azureService: AzureService,
        private configService: ConfigService,
        private cookieService: CookieService,
        private sessionService: SessionService
    ) {}

    requestToken(queryData?: Partial<Pick<Config['apiSettings'], 'branchId' | 'alternateSubject'>>) {
        const query = {
            client_id: this.configService.config.apiSettings.clientId,
            tenant_id: this.configService.config.apiSettings.tenantId,
            subject_type: this.configService.config.apiSettings.subjectType,
            subject: this.configService.config.apiSettings.subject,
            password: this.configService.config.apiSettings.password,

            branch_id: queryData?.branchId ?? this.configService.config.apiSettings.branchId,
            alternate_subject: queryData?.alternateSubject ?? this.configService.config.apiSettings.alternateSubject,
        };

        return this.apiService.post<{ token: string; token_expires: string }, typeof query>('token', query);
    }

    requestUserToken() {
        const user = JSON.parse<Employee | null>(this.cookieService.get(USER_COOKIE_KEY) || 'null');
        const branch = JSON.parse<Branch | null>(this.cookieService.get(BRANCH_COOKIE_KEY) || 'null');

        return this.requestToken({
            branchId: branch?.BrnId ?? undefined,
            alternateSubject: user?.Id ?? undefined,
        });
    }

    doAuth(employee: Employee, password: string, branchId: Branch['BrnId']) {
        const query = {
            EmpId: employee.Id,
            Password: password,
        };

        return this.apiService.post<AuthResponse>('employee_authentication', query).pipe(
            switchMap((authResponse) => {
                if (!authResponse.Authenticated && !authResponse.AzureAdAuthRequired) {
                    return of(authResponse);
                }

                const requestToken$ = this.requestToken({
                    branchId,
                    alternateSubject: employee.Id,
                }).pipe(map(() => authResponse));

                if (authResponse.AzureAdAuthRequired) {
                    return this.azureService.doAzureSignIn(employee.COEMPSEC_Emp!.Principal, authResponse).pipe(
                        switchMap((azureResponse) => {
                            if (azureResponse !== null) {
                                if (employee.COEMPSEC_Emp?.Principal !== azureResponse.account.username) {
                                    return of(authResponse);
                                }

                                return requestToken$;
                            }

                            return this.apiService
                                .post<AuthResponse>('employee_authentication', {
                                    ...query,
                                    ForceLdap: true,
                                })
                                .pipe(
                                    switchMap((ldapResponse) =>
                                        this.requestToken({
                                            branchId,
                                            alternateSubject: employee.Id,
                                        }).pipe(map(() => ldapResponse))
                                    )
                                );
                        })
                    );
                }

                return requestToken$;
            })
        );
    }

    logout() {
        return this.azureService.doAzureSignOut().pipe(
            tap({
                complete: () => {
                    this.sessionService.logout();
                },
            })
        );
    }
}
