<mat-toolbar
    *ngIf="(sessionService.kioskMode$ | async) === false"
    class="toolbar"
>
    <div></div>

    <div class="title">
        <app-svg-icon-renderer
            [style]="{ width: '26px' }"
            iconName="excede_connect_green"
        />
        <span>Document Delivery</span>
    </div>

    <button
        [matMenuTriggerFor]="menu"
        class="toolbar-button"
    >
        <app-svg-icon-renderer
            class="icon"
            iconName="employee"
        />

        Account
    </button>

    <mat-menu #menu="matMenu">
        <a
            routerLink="/about"
            ariaCurrentWhenActive="page"
            mat-menu-item
        >
            <mat-icon>info</mat-icon>
            <span>About</span>
        </a>

        <button mat-menu-item>
            <mat-icon>help</mat-icon>
            <span>Help</span>
        </button>

        <button
            mat-menu-item
            (click)="onLogout()"
        >
            <mat-icon>logout</mat-icon>
            <span>Logout</span>
        </button>
    </mat-menu>
</mat-toolbar>

<mat-sidenav-container
    *ngIf="(sessionService.kioskMode$ | async) === false; else content"
    class="sidenav-container"
>
    <mat-sidenav
        #drawer
        opened
        [attr.role]="'navigation'"
        mode="side"
        class="sidenav"
    >
        <mat-nav-list>
            <mat-list-item
                #link
                class="sidenav-link"
                data-collapsed="false"
            >
                <app-svg-icon-renderer
                    matListItemIcon
                    iconName="employee"
                />

                <span matListItemTitle>Configuration</span>

                <a
                    matListItemLine
                    routerLink="/contacts"
                    routerLinkActive="active-link"
                    ariaCurrentWhenActive="page"
                    >Contacts</a
                >
            </mat-list-item>
        </mat-nav-list>

        <div class="menu-container-footer">
            <div class="powered">Powered By:</div>
            <app-svg-icon-renderer
                [style]="{ width: '100px' }"
                iconName="excede_logo_color"
            />
            <div class="branch">Branch ID: {{ sessionService.loggedInBranch?.BrnId }}</div>
        </div>
    </mat-sidenav>

    <mat-sidenav-content>
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #content>
    <ng-content />
</ng-template>
