import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Config, ConfigDTO } from '../interfaces';
import { isNullOrUndefinedOrBlank } from '../utils';

@Injectable({
    providedIn: 'root',
})
export class ConfigService {
    config: Config = {
        apiSettings: {
            serviceUrl: '',
            clientId: '',
            branchId: '',
            tenantId: '',
            subjectType: '',
            subject: '',
            password: '',
            alternateSubject: '',
        },
        applicationSettings: {
            idleTimeoutSeconds: 300,
            alertTimeoutSeconds: 3,
        },
        version: '',
    };

    constructor(private http: HttpClient) {}

    public loadConfig() {
        return this.http.get<ConfigDTO>('./config.json').pipe(
            map((config: ConfigDTO) => {
                if (!config) {
                    return this.loadFromEnv();
                }

                let url = config.ApiSettings.serviceUrl;

                if (!isNullOrUndefinedOrBlank(url)) {
                    if (!url.endsWith('/')) url += '/';

                    if (!url.toLowerCase().endsWith('/api/')) url += '/api/';

                    url = url.replace(/([^:]\/)\/+/g, '$1');
                }

                console.debug('Loading config from config file');
                return {
                    apiSettings: {
                        serviceUrl: config.ApiSettings.serviceUrl,
                        clientId: config.ApiSettings.clientId,
                        branchId: config.ApiSettings.branchId,
                        tenantId: config.ApiSettings.tenantId,
                        subjectType: config.ApiSettings.subjectType,
                        subject: config.ApiSettings.subject,
                        password: config.ApiSettings.password,
                        alternateSubject: config.ApiSettings.alternateSubject,
                    },
                    applicationSettings: {
                        idleTimeoutSeconds: Number(config.ApplicationSettings.idleTimeoutSeconds),
                        alertTimeoutSeconds: Number(config.ApplicationSettings.alertTimeoutSeconds),
                    },
                    version: config.version,
                };
            }),
            catchError(() => of(this.loadFromEnv())),
            tap((config: Config) => (this.config = config))
        );
    }

    public getApiVersion(): Observable<string> {
        return this.http
            .get(this.config.apiSettings.serviceUrl + 'token', { observe: 'response' })
            .pipe(map((response) => response.headers.get('Api-Version') ?? ''));
    }

    private loadFromEnv(): Config {
        console.debug('Loading config from environment variables');
        return {
            apiSettings: {
                serviceUrl: environment.ApiSettings.serviceUrl,
                clientId: environment.ApiSettings.clientId,
                branchId: environment.ApiSettings.branchId,
                tenantId: environment.ApiSettings.tenantId,
                subjectType: environment.ApiSettings.subjectType,
                subject: environment.ApiSettings.subject,
                password: environment.ApiSettings.password,
                alternateSubject: environment.ApiSettings.alternateSubject,
            },
            applicationSettings: {
                idleTimeoutSeconds: Number(environment.ApplicationSettings.idleTimeoutSeconds),
                alertTimeoutSeconds: Number(environment.ApplicationSettings.alertTimeoutSeconds),
            },
            version: environment.version,
        };
    }
}
