import { Provider } from '@angular/core';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

export const angularMaterialProviders: Provider[] = [
    {
        provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
        useValue: {
            appearance: 'outline',
            subscriptSizing: 'dynamic',
        },
    },
    {
        provide: MAT_DIALOG_DEFAULT_OPTIONS,
        useValue: {
            backdropClass: 'dialog-backdrop',
        },
    },
    provideNativeDateAdapter(),
];
