import { animate, AUTO_STYLE, style, transition, trigger } from '@angular/animations';
import { DEFAULT_DURATION } from './defaultDuration';

export const collapseAnimation = trigger('collapse', [
    transition(':enter', [
        style({
            height: 0,
            opacity: 0,
            paddingTop: 0,
            paddingBottom: 0,
            overflow: 'hidden',
        }),
        animate(
            `${DEFAULT_DURATION}ms ease-in`,
            style({
                height: AUTO_STYLE,
                opacity: AUTO_STYLE,
                paddingTop: AUTO_STYLE,
                paddingBottom: AUTO_STYLE,
            })
        ),
    ]),
    transition(':leave', [
        style({
            height: AUTO_STYLE,
            opacity: AUTO_STYLE,
            paddingTop: AUTO_STYLE,
            paddingBottom: AUTO_STYLE,
            overflow: 'hidden',
        }),
        animate(
            `${DEFAULT_DURATION}ms ease-out`,
            style({
                height: 0,
                opacity: 0,
                paddingTop: 0,
                paddingBottom: 0,
            })
        ),
    ]),
]);
