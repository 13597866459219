import { Column } from '../../../common/components/edit-grid/Column';
import { ErrorItem } from './Error';

export const initialColumns: Column<ErrorItem>[] = [
    {
        field: 'CustomerName',
        headerName: 'Customer Name',
        inputType: 'template',
        cellTemplate: null,
    },
    {
        field: 'ContactName',
        headerName: 'Contact Name',
        inputType: 'text',
    },
    {
        field: 'EmailAddress',
        headerName: 'Email Address',
        inputType: 'text',
    },
    {
        field: 'DocumentID',
        headerName: 'Document ID',
        inputType: 'text',
        viewStyle: {
            textAlign: 'left',
        },
    },
    {
        field: 'DateSent',
        headerName: '',
        inputType: 'template',
        cellTemplate: null,
        headerStyle: {
            fontWeight: 'bold',
            color: '#666666',
        },
    },
    {
        field: 'Errors',
        headerName: 'Errors',
        inputType: 'text',
        viewStyle: {
            textAlign: 'left',
        },
    },
    {
        field: 'Resends',
        headerName: '# Resends',
        inputType: 'text',
        viewStyle: {
            textAlign: 'center',
        },
    },
];
