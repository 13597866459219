<section class="header">
    <span>Document Delivery</span>

    <div class="customer-search">
        <mat-label>Customer Name or ID</mat-label>

        <mat-form-field>
            <input
                matInput
                aria-label="Customer Name or ID"
                placeholder="Search"
                autocomplete="off"
                [matAutocomplete]="auto"
                [formControl]="customerSearchControl"
            />
            <mat-spinner
                *ngIf="searching$ | async"
                matSuffix
                diameter="24"
            />

            <mat-autocomplete
                #auto="matAutocomplete"
                [displayWith]="displayCustomer"
            >
                @for (customer of customers$ | async; track customer.Id) {
                    <mat-option [value]="customer">{{ customer.Name }} ({{ customer.Id }})</mat-option>
                }
            </mat-autocomplete>
        </mat-form-field>
    </div>

    <button
        mat-icon-button
        [matMenuTriggerFor]="menu"
        class="justify-end"
    >
        <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <button
            mat-menu-item
            (click)="openGuide('product_guide')"
        >
            Product Guide
        </button>
        <button
            mat-menu-item
            (click)="openGuide('troubleshoot_guide')"
        >
            Troubleshooting Guide
        </button>
        <button
            mat-menu-item
            (click)="openTab('errors')"
        >
            Error View
        </button>
        <button
            mat-menu-item
            (click)="openTab('about')"
        >
            About
        </button>
        <button
            mat-menu-item
            (click)="openTab('schedules')"
        >
            Schedules
        </button>
        <button
            mat-menu-item
            (click)="onTestApiArchivePermissions('ar_archive')"
        >
            Test AR Archive
        </button>
        <button
            mat-menu-item
            (click)="onTestApiArchivePermissions('invoice_archives')"
        >
            Test Invoice Archives
        </button>
        <mat-divider></mat-divider>
        <button
            mat-menu-item
            (click)="onLogout()"
        >
            Log Out
        </button>
    </mat-menu>
</section>

<mat-tab-group
    class="tab-group"
    mat-stretch-tabs="false"
    mat-align-tabs="start"
    animationDuration="0ms"
    (selectedTabChange)="tabChanged($event)"
>
    <mat-tab label="Setup">
        <ng-template matTabContent>
            <app-contacts-setup-tab [selectedCustomer]="selectedCustomer$" />
        </ng-template>
    </mat-tab>
    <mat-tab label="Delivery Status">
        <ng-template matTabContent>
            <app-delivery-status [selectedCustomer]="selectedCustomer$" />
        </ng-template>
    </mat-tab>

    <mat-tab
        *ngIf="extraTab"
        [label]="extraTab.title"
    >
        <ng-container *ngComponentOutlet="extraTab.component; inputs: extraTab.inputs" />
    </mat-tab>
</mat-tab-group>

<p
    *ngIf="extraTab?.title === 'Errors'"
    [style]="{ display: 'block', margin: '0', textAlign: 'center' }"
>
    Please view the Troubleshooting Guide for more information on possible causes.
</p>
